import React, { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import classNames from "classnames";
import qs from "qs";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import Icon from "../../components/Icon"
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";
import AudioPlayer from "../../components/AudioPlayer";

import { useAppSelector } from "../../store/hooks"
import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, startOfDateToReqServer, usePrevious } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports";

import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"
import "./Conversions.sass"

interface HTTPConversionsReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ConversionsDataProps
}

interface ConversionsDataProps {
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  edit: {
    source: string[]
  }

  dashboard: {
    active_orders: number
    all_orders: number
    from_calls: number
    sent_conversion: number
    sent_conversion_all: number
    sent_conversion_all_fb: number
    sent_conversion_fb: number
    underfined: number
    underfined_fb: number
    unsent: number
    unsent_fb: number
  },
  permissions: {
    conversion_edit_data: boolean
    conversion_send: boolean
    conversion_show_orders: boolean
    conversion_show_report: boolean
  }

  conversions: ConversionsProps[]
  measurement_id: string
  api_secret: string
}

interface ConversionsProps {
  ads: string
  appliances_brands: string
  back_chain: string
  call_direction: string
  call_sid: string
  call_status: string
  campaign: string
  campaign_id: string
  cid: string
  client_phone: string
  client_time: string
  created_at: string
  dispatcher: string
  email: string
  friendly_name: string
  gclid: string
  id: number
  ip: string
  ip_geo: string
  ip_isp: string
  keyword: string
  merge_id: string
  message: string
  order: string
  page: string
  phone: string
  promo: string
  service_resource: string
  source: string
  surf_duration: string
  type: string
  url: string
  visitor_id: string
  zip: string

  is_conversion: boolean
  is_conversion_fb: boolean
  is_sent_conversion: boolean
  is_sent_conversion_fb: boolean
  is_from_call: boolean
  is_unsent: boolean
  is_unsent_fb: boolean

  order_status: string

  call_recording_url: string
  call_duration: string
}

interface SendAllConversionsProps {
  conversions: ConversionsProps[]
  isOpen: boolean
  is_canceled: boolean
  is_sending: boolean
  success_send: number
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  let timeZone = user?.time_zone;

  const [conversionsView, setConversionsView] = useState<'google' | 'meta'>('google')

  const [callListenPopup, setCallListenPopup] = useState<string | null>(null)
  const [reportData, setReportData] = useState<ConversionsDataProps | null>(null)
  const [mergeList, setMergeList] = useState<ConversionsProps[] | []>([])
  const [nestedList, setNestedList] = useState<ConversionsProps[] | []>([])
  const [nestedFilterList, setNestedFilterList] = useState<{
    name_field: string,
    value: string,
    merge_id: string,
    back_chain_id: string,
    data: ConversionsProps[]
  }>({
    name_field: '',
    value: '',
    merge_id: '',
    back_chain_id: '',
    data: [],
  })

  const [nestedFilterDownList, setNestedFilterDownList] = useState<{
    name_field: string,
    value: string,
    merge_id: string,
    back_chain_id: string,
    data: ConversionsProps[]
  }>({
    name_field: '',
    value: '',
    merge_id: '',
    back_chain_id: '',
    data: [],
  })

  const [sendAllConversions, setSendAllConversions] = useState<SendAllConversionsProps>({
    conversions: [],
    isOpen: false,
    is_canceled: true,
    is_sending: false,
    success_send: 0,
  })

  const [selectedMerge, setSelectedMerge] = useState({
    merge_id: '',
    id: 0
  })
  const [selectedBackChain, setSelectedBackChain] = useState({
    backChain: '',
    id: 0
  })
  const [selectedPhone, setSelectedPhone] = useState({
    phone: '',
    id: 0
  })
  const [sendCidPopup, setSendCidPopup] = useState({
    cid: '',
    id: 0,
    mergeId: ''
  })
  const [nestedSelectedPhone, setNestedSelectedPhone] = useState({
    phone: '',
    id: 0
  })

  const [moveBackChain, setMoveBackChain] = useState({
    backChainFrom: '',
    id_field: 0
  })

  const [moveMerge, setMoveMerge] = useState<{
    mergeIdFrom: string
    backChainFrom: string
    cidFrom: string
    campaign: string
    campaign_id: string
    keyword: string
    id_field: number
    id_field_High: number
    is_from_call: null | boolean
    is_nested: boolean
  }>({
    mergeIdFrom: '',
    backChainFrom: '',
    cidFrom: '',
    campaign: '',
    campaign_id: '',
    keyword: '',
    id_field: 0,
    id_field_High: 0,
    is_from_call: null,
    is_nested: false
  })

  const [selectedMove, setSelectedMove] = useState({
    moveMergeId: false,
    moveClientId: false,
    moveCampaign: false,
    moveFromCall: false,
  })

  const [isFirstLoading, setIsFirstLoading] = useState(true)

  const [moveOrderUnset, setMoveOrderUnset] = useState(0)

  const [dataRangeOptions, setDataRangeOptions] = useState<string[]>(['google'])

  const [requestOption, setRequestOption] = useState('orders')

  const pervRequestOtion = usePrevious(requestOption)

  const [loading, setLoading] = useState(false)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [$updater, $setUpdater] = useState<any>(Math.random())
  const [localInterface, setLocalInterface] = useState({
    search: '',

    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),

    page: 1,

    filter_words: {
      source: [] as string[],
    },

    sort: {
      field: '',
      direction: 'down' as 'up' | 'down'
    },

    sortFields: [{
      span: '',
      value: ''
    }],

    appointmentSharePopup: false,

    dateRangeCalendarShown: false,
    dateRangeType: "" as string,
    dateRangePreset: "today" as DateRangePreset,
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: {
        source: [] as string[],
      },
      sort: {
        field: '',
        direction: 'down' as 'up' | 'down'
      },
      sortFields: [{
        span: '',
        value: ''
      }],
      appointmentSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangeType: "" as string,
      dateRangePreset: "today" as DateRangePreset,
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    if (conversionsView !== 'google') {
      if (dataRangeOptions.includes('facebook') || dataRangeOptions.includes('meta')) {
        let find = false
        dataRangeOptions.forEach(item => {
          if (!find && (item === 'facebook' || item === 'meta')) {
            find = true
            setLocalInterface({
              ...localInterface,
              dateRangeType: item
            })
            $setUpdater(Math.random())
          }
        })
      }
    } else {
      if (localInterface.dateRangeType !== 'google') {
        setLocalInterface({
          ...localInterface,
          dateRangeType: 'google'
        })
        $setUpdater(Math.random())
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionsView])

  // Load appointments function
  async function loadConversions() {
    try {
      let params: {
        account_id: string | null
        limit_rows: number
        page: number
        date_start: string | Date
        date_end: string | Date
        source?: string
      } = {
        account_id: activeAccountId,
        limit_rows: reportsMaxRows,
        page: localInterface.page,
        date_start: localInterface.min_date,
        date_end: localInterface.max_date,
      }
      if (localInterface.dateRangeType && dataRangeOptions.length > 1) {
        params.source = localInterface.dateRangeType
      }
      const { data: { data: conversions, success, error } } = await httpClientUpdate.get(`/conversions/${requestOption}`, {
        params: {
          ...params
        }
      }) as { data: HTTPConversionsReport }
      if (success) {
        setIsFirstLoading(false)
        setReportData({
          ...conversions,
          interface: {
            ...conversions.interface,
            min_date: DateTime.fromISO(conversions.interface.min_date as unknown as string).startOf('day').toJSDate(),
            max_date: DateTime.fromISO(conversions.interface.max_date as unknown as string).endOf('day').toJSDate(),
          }
        })

        setDataRangeOptions(conversions.edit.source)

        if (localInterface.dateRangeType === '' && conversions.edit.source.length) {
          setLocalInterface({
            ...localInterface,
            dateRangeType: conversions.edit.source[0]
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
        setIsFirstLoading(true)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function loadConversionsForSend() {
    try {
      let params: {
        account_id: string | null
        limit_rows: number
        page: number
        date_start: string | Date
        date_end: string | Date
        source?: string
      } = {
        account_id: activeAccountId,
        limit_rows: 500,
        page: 1,
        date_start: localInterface.min_date,
        date_end: localInterface.max_date,
      }
      if (localInterface.dateRangeType && dataRangeOptions.length > 1) {
        params.source = localInterface.dateRangeType
      }
      const { data: { data: conversions, success } } = await httpClientUpdate.get(`/conversions/${requestOption}`, {
        params: {
          ...params
        }
      }) as { data: HTTPConversionsReport }
      if (success) {
        setSendAllConversions({
          conversions: conversions.conversions.filter(item => item.type === 'order' && item.cid && !item.is_sent_conversion),
          isOpen: true,
          is_canceled: true,
          is_sending: false,
          success_send: 0,
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function loadConversionsFbForSend() {
    try {
      let params: {
        account_id: string | null
        limit_rows: number
        page: number
        date_start: string | Date
        date_end: string | Date
        source?: string
      } = {
        account_id: activeAccountId,
        limit_rows: 500,
        page: 1,
        date_start: localInterface.min_date,
        date_end: localInterface.max_date,
      }
      if (localInterface.dateRangeType && dataRangeOptions.length > 1) {
        params.source = localInterface.dateRangeType
      }
      const { data: { data: conversions, success } } = await httpClientUpdate.get(`/conversions/${requestOption}`, {
        params: {
          ...params
        }
      }) as { data: HTTPConversionsReport }
      if (success) {
        setSendAllConversions({
          conversions: conversions.conversions.filter(item => item.type === 'order' && item.cid && !item.is_sent_conversion_fb),
          isOpen: true,
          is_canceled: true,
          is_sending: false,
          success_send: 0,
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  function resetState() {
    setMergeList([])
    setNestedList([])
    setSelectedMerge({
      merge_id: '',
      id: 0
    })
    setSelectedBackChain({
      backChain: '',
      id: 0
    })
    setSelectedPhone({
      phone: '',
      id: 0
    })
    setSendCidPopup({
      cid: '',
      id: 0,
      mergeId: ''
    })
    setNestedSelectedPhone({
      phone: '',
      id: 0
    })
    setMoveBackChain({
      backChainFrom: '',
      id_field: 0
    })
    setMoveMerge({
      mergeIdFrom: '',
      backChainFrom: '',
      cidFrom: '',
      campaign: '',
      campaign_id: '',
      keyword: '',
      id_field: 0,
      id_field_High: 0,
      is_from_call: null,
      is_nested: false,
    })
    setSelectedMove({
      moveMergeId: false,
      moveClientId: false,
      moveCampaign: false,
      moveFromCall: false
    })
  }

  // Load conversions on page mount
  useEffect(() => {

    loadConversions();
    resetState()
    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
  ])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return

    isFirstLoading && setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  useEffect(() => {
    if (!!pervRequestOtion) {
      setLocalInterface({
        ...localInterface,
        page: 1
      })
      setMergeList([])
      setSelectedBackChain({
        backChain: '',
        id: 0
      })
      setSelectedMerge({
        merge_id: '',
        id: 0
      })
      setReportData(null)
      loadConversions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOption])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // download list
  async function downloadMergeLists() {
    const response = await httpClientUpdate.get(`/conversions/filter`, {
      params: {
        account_id: activeAccountId,
        'fields[merge_id]': [selectedMerge.merge_id]
      }
    })
    if (response && response.data.success) {
      setMergeList(response.data.data.conversions)
    }
  }

  // get all mergeId
  async function getMergeLists(merge_id: string) {
    const response = await httpClientUpdate.get(`/conversions/filter`, {
      params: {
        account_id: activeAccountId,
        'fields[merge_id]': [merge_id]
      }
    })
    if (response && response.data.success) {
      return response.data.data.conversions
    }
  }

  async function getBackChain(backChain: string) {
    const response = await httpClientUpdate.get(`/conversions/filter`, {
      params: {
        account_id: activeAccountId,
        'fields[back_chain]': [backChain]
      }
    })
    if (response && response.data.success) {
      return response.data.data.conversions
    }
  }

  async function handleGetDataWithFilter(name_filter: string, value: string, merge_id: string, back_chain_id: string) {
    let name_key = `fields[${name_filter}]`
    if (nestedFilterList.name_field === name_key &&
      nestedFilterList.value === value &&
      merge_id === nestedFilterList.merge_id) {
      setNestedFilterList({
        name_field: '',
        value: '',
        merge_id: '',
        back_chain_id: '',
        data: [],
      })

      setNestedFilterDownList({
        name_field: '',
        value: '',
        merge_id: '',
        back_chain_id: '',
        data: [],
      })
    } else {
      const response = await httpClientUpdate.get(`/conversions/filter`, {
        params: {
          account_id: activeAccountId,
          [name_key]: [value],
        }
      })

      if (response && response.data.success) {
        setNestedFilterList({
          name_field: name_key,
          value: value,
          merge_id: merge_id,
          back_chain_id: back_chain_id,
          data: response.data.data.conversions,
        })

        setNestedFilterDownList({
          name_field: '',
          value: '',
          merge_id: '',
          back_chain_id: '',
          data: [],
        })
      }
    }
  }

  async function handleGetNestedDataWithFilter(name_filter: string, value: string, merge_id: string, back_chain_id: string) {
    let name_key = `fields[${name_filter}]`
    if (nestedFilterDownList.name_field === name_key &&
      nestedFilterDownList.value === value &&
      merge_id === nestedFilterDownList.merge_id) {
      setNestedFilterDownList({
        name_field: '',
        value: '',
        merge_id: '',
        back_chain_id: '',
        data: [],
      })
    } else {
      const response = await httpClientUpdate.get(`/conversions/filter`, {
        params: {
          account_id: activeAccountId,
          [name_key]: [value],
        }
      })

      if (response && response.data.success) {
        setNestedFilterDownList({
          name_field: name_key,
          value: value,
          merge_id: merge_id,
          back_chain_id: back_chain_id,
          data: response.data.data.conversions,
        })

      }
    }
  }

  console.log('NESTED', nestedFilterDownList);


  // download backChain list
  async function downloadBackChainLists() {
    try {
      const response = await httpClientUpdate.get(`/conversions/filter`, {
        params: {
          account_id: activeAccountId,
          'fields[back_chain]': [selectedBackChain.backChain]
        }
      })
      if (response && response.data.success) {
        response.data.data.conversions.length &&
          setMergeList((response.data.data.conversions as ConversionsProps[]).filter(item => item.type !== 'order'))
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedMerge.merge_id) {
      downloadMergeLists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerge])

  useEffect(() => {
    if (selectedBackChain.backChain) {
      downloadBackChainLists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBackChain])

  async function getCallLists(dayEnd: string, dayEndFormat: string) {
    try {
      const response = await httpClientUpdate.get(`/conversions/filter`, {
        params: {
          account_id: activeAccountId,
          'fields[type]': ['call', 'send_form'],
          date_start: startOfDateToReqServer('MM/dd/yyyy hh:mma', timeZone, dayEndFormat),
          date_finish: dayEnd,
          source: localInterface.dateRangeType,
        }
      })
      if (response && response.data.success) {
        response.data.data.conversions.length &&
          setMergeList((response.data.data.conversions as ConversionsProps[]).filter(item => item.type === 'call' || item.type === 'send_form'))
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  async function getNestedLists(dayEnd: string, phone: string) {
    try {
      let dayEndFormat = dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, dayEnd)
      const response = await httpClientUpdate.get(`/conversions/filter`, {
        params: {
          account_id: activeAccountId,
          'fields[phone]': [phone],
          date_start: startOfDateToReqServer('MM/dd/yyyy hh:mma', timeZone, dayEndFormat),
          date_finish: DateTime.fromISO(dayEnd).plus({ minutes: 2 }).setZone(timeZone).toISO(),
        }
      })
      if (response && response.data.success) {
        setNestedList(response.data.data.conversions)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  // Move BackChain from order
  async function handleMoveBackChainFromOrder() {
    try {
      const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
        account_id: activeAccountId,
        back_chain: moveBackChain.backChainFrom,
        ids: [moveBackChain.id_field]
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })

      if (response.data.success) {
        let mergeListUpdate = mergeList.map(item => item)
        let index = mergeListUpdate.findIndex(item => item.id === moveBackChain.id_field);
        mergeListUpdate[index].back_chain = moveBackChain.backChainFrom

        setMergeList(mergeListUpdate)
        setMoveBackChain({
          backChainFrom: '',
          id_field: 0
        })
      }
    } catch (err) {

    }
  }


  // Move Order unset
  async function handleMoveOrderUnset() {
    try {
      const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
        account_id: activeAccountId,
        is_unsent: 1,
        ids: [moveOrderUnset]
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })

      if (response.data.success) {
        let conversionsUpdate = reportData?.conversions.map(item => item)
        if (conversionsUpdate && reportData) {
          let index = conversionsUpdate.findIndex(item => item.id === moveOrderUnset);
          conversionsUpdate[index].is_unsent = true

          setReportData({
            ...reportData,
            conversions: conversionsUpdate
          })
          setMoveOrderUnset(0)
        }
      }
    } catch (err) {

    }
  }

  async function handleMoveMergeFromOrder() {
    try {
      setSelectedMove({
        moveCampaign: false,
        moveClientId: false,
        moveMergeId: false,
        moveFromCall: false
      })
      if (selectedMove.moveMergeId) {
        let getIds = await getBackChain(moveMerge.backChainFrom)
        let campaign = moveMerge.campaign
        let campaign_id = moveMerge.campaign_id
        let keyword = moveMerge.keyword
        let cidFrom = moveMerge.cidFrom

        let getMergeListsReverse = moveMerge.is_nested ? nestedList.filter(item => item.merge_id === moveMerge.mergeIdFrom) : mergeList.map(item => item)
        let is_find_campaige = false
        let is_find_cid = false

        if (!campaign) {
          getMergeListsReverse.reverse().forEach(item => {
            if (!is_find_campaige && item.campaign) {
              is_find_campaige = true

              campaign = item.campaign
              campaign_id = item.campaign_id
              keyword = item.keyword
            }
          })
        }

        if (!cidFrom) {
          getMergeListsReverse.reverse().forEach(item => {
            if (!is_find_cid && item.cid && !cidFrom) {
              is_find_cid = true

              cidFrom = item.cid
            }
          })
        }

        const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
          account_id: activeAccountId,
          merge_id: moveMerge.mergeIdFrom,
          ids: (getIds as ConversionsProps[]).map(item => item.id)
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        })

        const responseCampage = await httpClientUpdate.put(`/conversions`, qs.stringify({
          account_id: activeAccountId,
          campaign: campaign,
          campaign_id: campaign_id,
          keyword: keyword,
          cid: cidFrom,
          is_conversion: cidFrom ? 1 : 0,
          ids: [moveMerge.id_field_High]
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        })

        if (response.data.success && responseCampage.data.success) {
          let conversionsUpdate = reportData?.conversions.map(item => item)
          if (conversionsUpdate && reportData) {
            let index = conversionsUpdate.findIndex(item => item.id === moveMerge.id_field_High);
            conversionsUpdate[index].merge_id = moveMerge.mergeIdFrom
            conversionsUpdate[index].campaign = campaign ? campaign : conversionsUpdate[index].campaign
            conversionsUpdate[index].campaign_id = campaign_id ? campaign_id : conversionsUpdate[index].campaign_id
            conversionsUpdate[index].keyword = keyword ? keyword : conversionsUpdate[index].keyword
            conversionsUpdate[index].cid = cidFrom ? cidFrom : conversionsUpdate[index].cid
            conversionsUpdate[index].is_conversion = cidFrom ? true : false

            setReportData({
              ...reportData,
              conversions: conversionsUpdate
            })

            setMoveMerge({
              mergeIdFrom: '',
              backChainFrom: '',
              cidFrom: '',
              campaign: '',
              campaign_id: '',
              keyword: '',
              id_field: 0,
              id_field_High: 0,
              is_from_call: null,
              is_nested: false
            })
          }
        }
      }

      if (selectedMove.moveFromCall) {
        const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
          account_id: activeAccountId,
          is_from_call: moveMerge.is_from_call,
          ids: [moveMerge.id_field_High]
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        })

        if (response.data.success) {
          let conversionsUpdate = reportData?.conversions.map(item => item)
          if (conversionsUpdate && reportData && moveMerge.is_from_call !== null) {
            let index = conversionsUpdate.findIndex(item => item.id === moveMerge.id_field_High);
            conversionsUpdate[index].is_from_call = moveMerge.is_from_call

            setReportData({
              ...reportData,
              conversions: conversionsUpdate
            })
            setMoveMerge({
              mergeIdFrom: '',
              backChainFrom: '',
              cidFrom: '',
              campaign: '',
              campaign_id: '',
              keyword: '',
              id_field: 0,
              id_field_High: 0,
              is_from_call: null,
              is_nested: false,
            })
          }
        }
      }

      if (selectedMove.moveCampaign) {
        const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
          account_id: activeAccountId,
          campaign: moveMerge.campaign,
          campaign_id: moveMerge.campaign_id,
          keyword: moveMerge.keyword,
          ids: [moveMerge.id_field_High]
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        })

        if (response.data.success) {
          let conversionsUpdate = reportData?.conversions.map(item => item)
          if (conversionsUpdate && reportData) {
            let index = conversionsUpdate.findIndex(item => item.id === moveMerge.id_field_High);
            conversionsUpdate[index].campaign = moveMerge.campaign
            conversionsUpdate[index].campaign_id = moveMerge.campaign_id
            conversionsUpdate[index].keyword = moveMerge.keyword

            setReportData({
              ...reportData,
              conversions: conversionsUpdate
            })
          }
          setMoveMerge({
            mergeIdFrom: '',
            backChainFrom: '',
            cidFrom: '',
            campaign: '',
            campaign_id: '',
            keyword: '',
            id_field: 0,
            id_field_High: 0,
            is_from_call: null,
            is_nested: false,
          })
        }
      }

      if (selectedMove.moveClientId) {
        const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
          account_id: activeAccountId,
          cid: moveMerge.cidFrom,
          is_conversion: 1,
          ids: [moveMerge.id_field_High]
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        })

        if (response.data.success) {
          let conversionsUpdate = reportData?.conversions.map(item => item)
          if (conversionsUpdate && reportData) {
            let index = conversionsUpdate.findIndex(item => item.id === moveMerge.id_field_High);
            conversionsUpdate[index].cid = moveMerge.cidFrom
            conversionsUpdate[index].is_conversion = true

            setReportData({
              ...reportData,
              conversions: conversionsUpdate
            })
          }
          setMoveMerge({
            mergeIdFrom: '',
            backChainFrom: '',
            cidFrom: '',
            campaign: '',
            campaign_id: '',
            keyword: '',
            id_field: 0,
            id_field_High: 0,
            is_from_call: null,
            is_nested: false,
          })
        }
      }
    } catch (err) { }
  }

  function handleMerge(mergeId: string, id: number) {
    setSelectedPhone({
      phone: '',
      id: 0
    })
    setSelectedBackChain({
      backChain: '',
      id: 0
    })
    setNestedSelectedPhone({
      phone: '',
      id: 0
    })
    setNestedList([])
    setMergeList([])
    if (selectedMerge.merge_id) {
      if (selectedMerge.id === id) {
        setSelectedMerge({
          merge_id: '',
          id: 0
        })
      } else {
        setSelectedMerge({
          merge_id: mergeId,
          id: id
        })
      }
    } else {
      setSelectedMerge({
        merge_id: mergeId,
        id: id
      })
    }
  }

  function handleBackChain(backChain: string, id: number) {
    setSelectedPhone({
      phone: '',
      id: 0
    })
    setSelectedMerge({
      merge_id: '',
      id: 0
    })
    setNestedSelectedPhone({
      phone: '',
      id: 0
    })
    setMergeList([])
    setNestedList([])
    if (selectedBackChain.backChain) {
      if (selectedBackChain.id === id) {
        setSelectedBackChain({
          backChain: '',
          id: 0
        })
      } else {
        setLoading(true)
        setSelectedBackChain({
          backChain: backChain,
          id: id
        })
      }
    } else {
      setLoading(true)
      setSelectedBackChain({
        backChain: backChain,
        id: id
      })
    }
  }

  function handlePhone(dayEnd: string, dayEndFormat: string, id: number, phone: string) {
    setMergeList([])
    setNestedList([])
    setSelectedBackChain({
      backChain: '',
      id: 0
    })
    setSelectedMerge({
      merge_id: '',
      id: 0
    })
    setNestedSelectedPhone({
      phone: '',
      id: 0
    })
    if (selectedPhone.id) {
      if (selectedPhone.id === id) {
        setSelectedPhone({
          phone: '',
          id: 0
        })
        setMergeList([])
      } else {
        setSelectedPhone({
          phone: phone,
          id: id
        })
        getCallLists(dayEnd, dayEndFormat)
      }
    } else {
      setSelectedPhone({
        phone: phone,
        id: id
      })
      getCallLists(dayEnd, dayEndFormat)
    }
  }

  function handleNestedPhone(phone: string, id: number, day: string) {
    if (nestedSelectedPhone.id) {
      if (nestedSelectedPhone.id === id) {
        setNestedSelectedPhone({
          phone: '',
          id: 0
        })
      } else {
        setLoading(true)
        setNestedSelectedPhone({
          phone: phone,
          id: id
        })
        getNestedLists(day, phone)
      }
    } else {
      setLoading(true)
      setNestedSelectedPhone({
        phone: phone,
        id: id
      })
      getNestedLists(day, phone)
    }
  }

  function handleCidPopup(cid: string, id: number, mergeId: string) {
    setSendCidPopup({
      cid: cid,
      id: id,
      mergeId: mergeId
    })
  }

  function handleMoveDownButton(backChainFrom: string, id_field: number) {
    setMoveBackChain({
      backChainFrom,
      id_field
    })
  }

  async function handleSendAllConversions() {
    try {
      setSendAllConversions({
        ...sendAllConversions,
        is_sending: true
      })

      let count = 0

      let conversions = sendAllConversions.is_canceled ? sendAllConversions.conversions : sendAllConversions.conversions.filter(item => item.order_status !== 'canceled')

      for (const conversion of conversions) {

        const sendConversion = await handleSendAllSid(conversion.cid, conversion.merge_id, conversion.id)

        if (sendConversion) {
          count = count + 1

          setSendAllConversions({
            ...sendAllConversions,
            is_sending: true,
            success_send: count
          })
        }
      }

    } catch (error) { }
  }

  async function handleSendAllConversionsFb() {
    try {
      setSendAllConversions({
        ...sendAllConversions,
        is_sending: true
      })

      let count = 0

      let conversions = sendAllConversions.is_canceled ? sendAllConversions.conversions : sendAllConversions.conversions.filter(item => item.order_status !== 'canceled')

      for (const conversion of conversions) {

        const sendConversion = await handleSendConversionFb(conversion.id)

        if (sendConversion) {
          count = count + 1

          setSendAllConversions({
            ...sendAllConversions,
            is_sending: true,
            success_send: count
          })
        }
      }

    } catch (error) { }
  }

  async function handleSendAllSid(cid: string, mergeId: string, id: number) {
    try {

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let fetchResults = await fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${reportData?.measurement_id}&api_secret=${reportData?.api_secret}`, {
        method: "POST",
        body: JSON.stringify({
          "client_id": cid,
          "events": [{
            "name": "new_order",
          }]
        })
      });
      let getIdMergeId = await getMergeLists(mergeId)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
        account_id: activeAccountId,
        is_sent_conversion: 1,
        ids: (getIdMergeId as ConversionsProps[]).map(item => item.id)
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })

      let conversionsUpdate = reportData?.conversions.map(item => item)

      if (conversionsUpdate && conversionsUpdate.length && reportData) {
        let index = conversionsUpdate.findIndex(item => item.id === id);

        if (index >= 0) {
          conversionsUpdate[index].is_sent_conversion = true

          setReportData({
            ...reportData,
            conversions: conversionsUpdate
          })
        }
      }

      if (response.data.success) {
        return true
      }
      return true
    } catch (err) { }
  }

  async function handleSendConversionFb(id: number) {
    try {
      // https://2022back4.artemiudintsev.com/api/conversions/facebook/send
      const response = await httpClientUpdate.post(`/conversions/facebook/send`, qs.stringify({
        account_id: activeAccountId,
        conversion_id: id,
        is_sent_conversion_fb: 1,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })

      let conversionsUpdate = reportData?.conversions.map(item => item)

      if (conversionsUpdate && conversionsUpdate.length && reportData) {
        let index = conversionsUpdate.findIndex(item => item.id === id);

        if (index >= 0) {
          conversionsUpdate[index].is_conversion_fb = true

          setReportData({
            ...reportData,
            conversions: conversionsUpdate
          })
        }
      }

      if (response.data.success) {
        return true
      }
      return true
    } catch (error) { }
  }

  async function handleSendSid() {
    try {
      let fetchResults = await fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${reportData?.measurement_id}&api_secret=${reportData?.api_secret}`, {
        method: "POST",
        body: JSON.stringify({
          "client_id": sendCidPopup.cid,
          "events": [{
            "name": "new_order",
          }]
        })
      });
      let getIdMergeId = await getMergeLists(sendCidPopup.mergeId)

      const response = await httpClientUpdate.put(`/conversions`, qs.stringify({
        account_id: activeAccountId,
        is_sent_conversion: 1,
        ids: (getIdMergeId as ConversionsProps[]).map(item => item.id)
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      console.log('fetchResults', fetchResults);
      console.log(response, response);
      console.log('SID', sendCidPopup.cid)
      let conversionsUpdate = reportData?.conversions.map(item => item)
      if (conversionsUpdate && reportData) {
        let index = conversionsUpdate.findIndex(item => item.id === sendCidPopup.id);
        conversionsUpdate[index].is_sent_conversion = true

        setReportData({
          ...reportData,
          conversions: conversionsUpdate
        })
      }

      setSendCidPopup({
        cid: '',
        id: 0,
        mergeId: ''
      })

    } catch (err) {

    }
  }

  async function handleSendSidFb() {
    try {

      // https://2022back4.artemiudintsev.com/api/conversions/facebook/send
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await httpClientUpdate.post(`/conversions/facebook/send`, qs.stringify({
        account_id: activeAccountId,
        conversion_id: sendCidPopup.id,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })

      let conversionsUpdate = reportData?.conversions.map(item => item)
      if (conversionsUpdate && reportData) {
        let index = conversionsUpdate.findIndex(item => item.id === sendCidPopup.id);
        conversionsUpdate[index].is_sent_conversion_fb = true

        setReportData({
          ...reportData,
          conversions: conversionsUpdate
        })
      }

      setSendCidPopup({
        cid: '',
        id: 0,
        mergeId: ''
      })

    } catch (err) {

    }
  }

  function getServiceRow(service: string, zip: string) {
    let row = ''

    if (!!service && !!zip) {
      row = `${service} - ${zip}`
    }

    if (!!service && !zip) {
      row = service
    }

    if (!service && !!zip) {
      row = zip
    }

    return row
  }

  function getSentConversion() {
    let sent_conversion = 0
    let sent_conversion_all = 0

    if (conversionsView === 'google') {
      sent_conversion = reportData?.dashboard?.sent_conversion || 0
      sent_conversion_all = reportData?.dashboard?.sent_conversion_all || 0
    } else {
      sent_conversion = reportData?.dashboard?.sent_conversion_fb || 0
      sent_conversion_all = reportData?.dashboard?.sent_conversion_all_fb || 0
    }


    return `${sent_conversion}/${sent_conversion_all}`
  }

  function getCallsDashboard() {
    let from_calls = 0
    let unsent = 0

    from_calls = reportData?.dashboard.from_calls || 0

    if (conversionsView === 'google') {
      unsent = reportData?.dashboard?.unsent || 0
    } else {
      unsent = reportData?.dashboard?.unsent_fb || 0
    }

    return `${from_calls}/${unsent}`
  }

  function getUndefinedDashboard() {
    let underfined = 0


    if (conversionsView === 'google') {
      underfined = reportData?.dashboard?.underfined || 0
    } else {
      underfined = reportData?.dashboard?.underfined_fb || 0
    }

    return `${underfined}`
  }

  return (
    <>
      {
        reportData ?
          <div className="AppointmentsPage_List" >

            { /* Reports grid */}
            <div className="reports-grid">

              <div className="cell">

                <div className="amount">{reportData.dashboard.active_orders || 0}/{reportData?.dashboard.all_orders || 0}</div>
                <div className="legend">Active/All Orders</div>
              </div>

              <div className="cell">

                <div className="amount">{getSentConversion()}</div>
                <div className="legend">Sent Conversions</div>
              </div>

              <div className="cell">

                <div className="amount">{getCallsDashboard()}</div>
                <div className="legend">From Calls/Unsent</div>
              </div>

              <div className="cell">

                <div className="amount">{getUndefinedDashboard()}</div>
                <div className="legend">Underfined</div>
              </div>
            </div>

            { /* Page header */}
            <div className="page-header page-header-conversions">
              <h1>Conversions</h1>

              <div className="page-header-conversions-btns">
                <button
                  className={classNames("_wa _iconed _bordered _blue",
                    {
                      __active: conversionsView === 'google',
                    }
                  )}
                  onClick={() => setConversionsView('google')}
                >
                  <div className="page-header-conversions-btns-icon">
                    <Icon viewBox="0 0 1024 1024" icon="google-58" />
                  </div>

                  Google
                </button>

                <button
                  className={classNames("_wa _iconed _bordered _blue",
                    {
                      __active: conversionsView === 'meta',
                    }
                  )}
                  onClick={() => setConversionsView('meta')}
                >
                  <div className="page-header-conversions-btns-icon">
                    <Icon viewBox="0 0 48 48" icon="meta-1" />
                  </div>

                  Meta
                </button>
              </div>
            </div>

            { /* List filters */}
            <ReportFilters
              onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value, page: 1 })}
              searchInput={localInterface.search}
              searchButtonActive={searchButtonActive}

              dateRangeType={localInterface.dateRangeType}
              onDateRangeTypeChange={(value) => setLocalInterface({ ...localInterface, dateRangeType: value as any, page: 1 })}
              dataRangeOptions={dataRangeOptions}

              dateRangePreset={localInterface.dateRangePreset}
              onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

              minDate={localInterface.min_date}
              onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

              maxDate={localInterface.max_date}
              onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

              updateButtonActive={reportIsDeprecated}
              onUpdate={() => {
                setIsFirstLoading(false)
                $setUpdater(Math.random())
              }}

              phoneCall={phoneCall}
              navActive={navActive.is}

              childrenAfterSearch={(
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div style={{
                    width: '-webkit-fill-available',
                    minWidth: '80px',
                  }}>
                    <Select
                      disabled={!reportData.permissions.conversion_show_report}
                      zeroed={true}
                      options={[{
                        span: "Orders",
                        value: "orders"
                      }, {
                        span: "Report",
                        value: "report"
                      }]}
                      selectedOption={requestOption}
                      onChange={(value: string) => {
                        setRequestOption(value)
                      }}
                    />

                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '10px',
                      width: '30px',
                    }}
                    onClick={() => reportData.permissions.conversion_send &&
                      ((conversionsView === 'google' || !['facebook', 'meta'].includes(localInterface.dateRangeType)) ? loadConversionsForSend() : loadConversionsFbForSend())
                    }
                  >
                    <Icon
                      viewBox="0 0 1024 1024"
                      style={{
                        width: '16px',
                        minWidth: '16px',
                        height: '16px',
                        marginRight: '5px',
                        cursor: 'pointer',
                        opacity: reportData.permissions.conversion_send ? .9 : .2
                      }}
                      icon="send-21"
                    />
                  </div>
                </div>)}
            />

            {/* Table controls */}
            <ReportTableControls
              zIndex={5}

              onMaxRowsChange={() => {
                $setUpdater(Math.random())
              }}

              amount={{
                total: reportData.interface.rows_all,
                start: reportData.interface.rows_start,
                end: reportData.interface.rows_end
              }}

              page={localInterface.page}
              maxPages={reportData.interface.max_pages}
              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
                $setUpdater(Math.random())
              }}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}
            />

            <div className="contents conversions">
              {/* Wide desktop table */}
              <div className={classNames('table', '__hide-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>
                <div className="tr"></div>
                {
                  reportData?.conversions.map((item, index) => (
                    <React.Fragment key={index}>
                      <div
                        className={classNames('tr',
                          {
                            order: item.type === 'order',
                            call: item.type === 'call',
                            send_form: item.type === 'send_form',
                            visit: item.type === 'visit',
                            click_call: item.type === 'click_call',
                            click: item.type === 'click',
                            surf: item.type === 'surf',
                            promo: item.type === 'promo',
                            selected_tr: selectedMerge.id === item.id || selectedBackChain.id === item.id || selectedPhone.id === item.id
                          })}
                      >
                        <div className="td">
                          <div> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)} </div>
                          <div>
                            <p>
                              {item.type}
                            </p>
                            {
                              item.type === 'surf' && item.surf_duration &&
                              <p>
                                ({item.surf_duration})
                              </p>
                            }
                          </div>
                        </div>

                        <div className="td">
                          <div>
                            {item.type !== 'order' && item.ip}
                            {
                              item.type === 'order' &&
                              <div
                                className="indicator-block"
                                onClick={() => reportData.permissions.conversion_edit_data && setMoveOrderUnset(item.id)}
                              >
                                <span
                                  className={classNames({
                                    conversion: conversionsView === 'google' ? item.is_conversion : item.is_conversion_fb,
                                    sent: conversionsView === 'google' ? item.is_sent_conversion : item.is_sent_conversion_fb,
                                    call: item.is_from_call,
                                    unsent: conversionsView === 'google' ? item.is_unsent : item.is_unsent_fb,
                                  })}
                                >
                                  Canceled
                                </span>
                              </div>
                            }
                          </div>

                          <div>
                            {item.type !== 'order' && item.ip_isp}
                            {
                              item.type === 'order' && item.order_status && item.order_status.toLowerCase() === 'canceled' &&
                              <div className="order-status">
                                <span>
                                  Canceled
                                </span>
                              </div>
                            }
                          </div>
                        </div>

                        <div className="td">
                          <div>
                            {item.type !== 'call' && item.type !== 'click' && item.type !== 'order' && item.cid}
                            {item.type === 'click' && item.client_phone}
                            {
                              item.type === 'order' ?
                                item.cid ?
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                    onClick={() => reportData.permissions.conversion_send && handleCidPopup(item.cid, item.id, item.merge_id)}
                                  >
                                    <Icon
                                      viewBox="0 0 1024 1024"
                                      style={{
                                        width: '16px',
                                        minWidth: '16px',
                                        height: '16px',
                                        marginRight: '5px',
                                        cursor: 'pointer',
                                        opacity: reportData.permissions.conversion_send ? .5 : .2
                                      }}
                                      icon="send-21"
                                    />
                                    {item.cid}
                                  </div> :
                                  <div> N/A </div> :
                                null
                            }
                          </div>
                          <div>
                            {item.type === 'order' ?
                              item.merge_id ?
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-end'
                                  }}
                                  className={classNames({
                                    selected: item.id === selectedMerge.id
                                  })}
                                  onClick={() => reportData.permissions.conversion_edit_data && handleMerge(item.merge_id, item.id)}
                                >
                                  <Icon
                                    viewBox="0 0 16 16"
                                    style={{
                                      width: '16px',
                                      minWidth: '16px',
                                      height: '16px',
                                      marginRight: '5px',
                                      cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                      opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                    }}
                                    icon="download-1"
                                  />
                                  {item.merge_id}
                                </div> :
                                item.merge_id :
                              item.merge_id}
                          </div>
                        </div>

                        <div className="td">
                          <div>
                            {item.type !== 'order' && item.type !== 'call' && item.page}
                            {item.type === 'call' && item.friendly_name}
                            {item.type === 'order' && item.order}
                          </div>
                          <div>
                            {item.type !== 'order' && item.type !== 'call' && item.ip_geo}
                            {item.type === 'call' && item.back_chain}
                            {item.type === 'order' && item.back_chain &&
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end'
                                }}
                                className={classNames({
                                  selected: item.id === selectedBackChain.id
                                })}
                                onClick={() => !loading && reportData.permissions.conversion_edit_data && handleBackChain(item.back_chain, item.id)}
                              >
                                <Icon
                                  viewBox="0 0 16 16"
                                  style={{
                                    width: '16px',
                                    minWidth: '16px',
                                    height: '16px',
                                    marginRight: '5px',
                                    cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                    opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                  }}
                                  icon="download-1"
                                />
                                {item.back_chain}
                              </div>
                            }
                          </div>
                        </div>

                        <div className="td">
                          <div>
                            {item.type === 'call' && item.dispatcher}
                            {item.type !== 'call' && item.type !== 'order' && item.visitor_id}
                            {item.type === 'order' && item.dispatcher}
                            {/* {item.fifth_one} */}
                          </div>
                          <div>
                            {item.type === 'send_form' && item.back_chain}
                            {item.type === 'call' && `${item.call_direction ? item.call_direction : ''} / ${item.call_status ? item.call_status : ''}`}
                            {item.type === 'order' && `${item.campaign ? item.campaign : ''} ${item.campaign_id ? '(' + item.campaign_id + ')' : ''}`}
                            {item.type === 'click' &&
                              <p className="text-dots" title={item.gclid} >
                                {item.gclid}
                              </p>
                            }
                            {/* {item.fifth_two}  */}
                          </div>
                        </div>

                        <div className="td">
                          <div>
                            {item.type === 'promo' && item.client_phone}
                            {item.type === 'call' && item.client_phone}
                            {item.type === 'send_form' && item.client_phone}
                            {item.type === 'click' && `${item.campaign ? item.campaign : ''} ${item.campaign_id ? '(' + item.campaign_id + ')' : ''}`}

                            {item.type === 'order' &&
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end'
                                }}
                                className={classNames({
                                  selected: item.id === selectedPhone.id
                                })}
                                onClick={() => reportData.permissions.conversion_edit_data && handlePhone(item.created_at, dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at), item.id, item.client_phone)}
                              >
                                <Icon
                                  viewBox="0 0 16 16"
                                  style={{
                                    width: '28px',
                                    minWidth: '16px',
                                    height: '16px',
                                    marginRight: '5px',
                                    cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                    opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                  }}
                                  icon="download-1"
                                />
                                {item.client_phone}
                              </div>
                            }
                          </div>
                          <div>
                            {item.type === 'surf' && item.phone}
                            {item.type === 'call' && item.phone}
                            {item.type === 'click_call' && item.phone}
                            {item.type === 'promo' && item.phone}
                            {
                              item.type === 'order' &&
                              getServiceRow(item.service_resource, item.zip)
                            }
                            {item.type === 'send_form' && item.phone}
                            {item.type === 'visit' && item.phone}
                            {item.type === 'click' && item.phone}
                          </div>
                        </div>

                        <div className="td">
                          {
                            item.type === 'call' &&
                            item.call_recording_url &&
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <button
                                className="_zeroed _iconed"
                                onClick={() => setCallListenPopup(item.call_recording_url)}
                              >
                                <Icon style={{ fill: '#000' }} icon="media-control-48" />
                              </button>
                              <span style={{ marginLeft: '5px' }}> {item.call_duration} </span>
                            </div>
                          }
                          <div>
                            {item.type === 'send_form' && item.message}
                            {item.type === 'click' && item.keyword}
                            {item.type === 'order' && item.appliances_brands}
                            {item.type === 'promo' && item.email}
                          </div>
                          <div>

                            {item.type === 'surf' && item.client_time}
                            {item.type === 'click_call' && item.client_time}
                            {item.type === 'click' && item.ads}
                            {/* {item.type === 'call' && item.back_chain} */}
                            {/* {item.type === 'send_form' && item.back_chain} */}
                            {item.type === 'promo' && item.back_chain}
                            {item.type === 'order' && item.keyword}
                          </div>
                        </div>
                      </div>

                      {/* nested empty */}
                      {
                        item.type === 'order' &&
                        (selectedBackChain.id === item.id || selectedPhone.id === item.id) &&
                        !mergeList.length &&
                        !loading &&
                        <div
                          className="tr nested-items order"
                        >
                          <div className="nested td" >
                            <Icon
                              viewBox="0 0 1024 1024"
                              style={{
                                width: '16px',
                                minWidth: '16px',
                                height: '16px',
                                marginRight: '5px'
                              }}
                              icon="share-65"
                            />
                          </div>
                          <div className="nested-empty">
                          </div>
                        </div>
                      }

                      {/* nested table */}
                      {
                        item.type === 'order' &&
                        (selectedMerge.id === item.id || selectedBackChain.id === item.id || selectedPhone.id === item.id) &&
                        mergeList.map((itemMerge, idx) => (
                          <React.Fragment key={idx}>
                            {/* changed line */}
                            <div
                              className={classNames('tr nested-items',
                                {
                                  order: itemMerge.type === 'order',
                                  call: itemMerge.type === 'call',
                                  send_form: itemMerge.type === 'send_form',
                                  visit: itemMerge.type === 'visit',
                                  click_call: itemMerge.type === 'click_call',
                                  click: itemMerge.type === 'click',
                                  surf: itemMerge.type === 'surf',
                                  promo: itemMerge.type === 'promo'
                                })}
                            >
                              <div className="nested td" >
                                <span
                                  className="arrow-up"
                                  style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                  onClick={() => reportData.permissions.conversion_edit_data && setMoveMerge({
                                    mergeIdFrom: itemMerge.merge_id,
                                    backChainFrom: item.back_chain,
                                    cidFrom: itemMerge.cid,
                                    campaign: itemMerge.campaign,
                                    campaign_id: itemMerge.campaign_id,
                                    keyword: itemMerge.keyword,
                                    id_field: itemMerge.id,
                                    id_field_High: item.id,
                                    is_from_call: itemMerge.type === 'call' ? itemMerge.is_from_call : null,
                                    is_nested: false,
                                  })}
                                ></span>
                                <span
                                  className="arrow-down"
                                  style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                  onClick={() => reportData.permissions.conversion_edit_data && handleMoveDownButton(item.back_chain, itemMerge.id)}
                                ></span>
                              </div>
                              <div className="nested-item td" >
                                <div> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, itemMerge.created_at)} </div>
                                <div>
                                  <p>
                                    {itemMerge.type}
                                  </p>
                                  {
                                    itemMerge.type === 'surf' && itemMerge.surf_duration &&
                                    <p>
                                      ({itemMerge.surf_duration})
                                    </p>
                                  }
                                </div>
                              </div>

                              <div className="nested-item td" >
                                <div
                                  className={classNames({
                                    __bold: `${itemMerge.id}` === `${nestedFilterList.merge_id}` && itemMerge.ip === nestedFilterList.value
                                  })}
                                  onClick={() => itemMerge.ip && handleGetDataWithFilter('ip', itemMerge.ip, `${itemMerge.id}`, '')}
                                >
                                  {itemMerge.ip}
                                </div>
                                <div>
                                  {itemMerge.type !== 'order' && itemMerge.ip_isp}
                                  {
                                    itemMerge.type === 'order' && itemMerge.order_status && itemMerge.order_status.toLowerCase() === 'canceled' &&
                                    <div className="order-status">
                                      <span>
                                        Canceled
                                      </span>
                                    </div>
                                  }
                                </div>
                              </div>

                              <div className="nested-item td" >
                                <div
                                  className={classNames({
                                    __bold: `${itemMerge.id}` === `${nestedFilterList.merge_id}` &&
                                      itemMerge.type !== 'call' && itemMerge.cid &&
                                      itemMerge.cid === nestedFilterList.value
                                  })}
                                  onClick={() => itemMerge.type !== 'call' && itemMerge.cid && handleGetDataWithFilter('cid', itemMerge.cid, `${itemMerge.id}`, '')}
                                >
                                  {itemMerge.type !== 'call' && itemMerge.cid}
                                  {/* {itemMerge.type === 'click' && itemMerge.client_phone} */}
                                </div>
                                <div>
                                  {itemMerge.merge_id}
                                </div>
                              </div>

                              <div className="nested-item td" >
                                <div>
                                  {itemMerge.type !== 'order' && itemMerge.type !== 'call' && itemMerge.page}
                                  {itemMerge.type === 'call' && itemMerge.friendly_name}
                                  {itemMerge.type === 'order' && itemMerge.order}
                                </div>
                                <div>
                                  {itemMerge.type !== 'order' && itemMerge.type !== 'call' && itemMerge.ip_geo}
                                  {itemMerge.type === 'order' && itemMerge.back_chain}
                                  {itemMerge.type === 'call' && itemMerge.back_chain}
                                </div>
                              </div>

                              <div className="nested-item td" >
                                <div
                                  className={classNames({
                                    __bold: `${itemMerge.id}` === `${nestedFilterList.merge_id}` &&
                                      itemMerge.type !== 'order' &&
                                      itemMerge.type !== 'call' &&
                                      itemMerge.visitor_id && itemMerge.visitor_id === nestedFilterList.value
                                  })}
                                  onClick={() => itemMerge.type !== 'order' &&
                                    itemMerge.type !== 'call' &&
                                    itemMerge.visitor_id &&
                                    handleGetDataWithFilter('visitor_id', itemMerge.visitor_id, `${itemMerge.id}`, '')}
                                >
                                  {itemMerge.type === 'call' && itemMerge.dispatcher}
                                  {itemMerge.type !== 'order' && itemMerge.type !== 'call' && itemMerge.visitor_id}
                                  {itemMerge.type === 'order' && itemMerge.dispatcher}
                                  {/* {item.fifth_one} */}
                                </div>
                                <div>
                                  {itemMerge.type === 'send_form' && itemMerge.back_chain}
                                  {itemMerge.type === 'call' && `${itemMerge.call_direction ? itemMerge.call_direction : ''} / ${itemMerge.call_status ? itemMerge.call_status : ''}`}
                                  {itemMerge.type === 'order' && `${itemMerge.campaign ? itemMerge.campaign : ''} ${itemMerge.campaign_id ? '(' + itemMerge.campaign_id + ')' : ''}`}
                                  {itemMerge.type === 'click' &&
                                    <p className="text-dots" title={itemMerge.gclid} >
                                      {itemMerge.gclid}
                                    </p>
                                  }
                                  {/* {item.fifth_two}  */}
                                </div>
                              </div>

                              <div className="nested-item td" >
                                <div>
                                  {itemMerge.type === 'promo' && itemMerge.client_phone}
                                  {itemMerge.type === 'order' && itemMerge.client_phone}
                                  {itemMerge.type === 'call' && itemMerge.client_phone}
                                  {itemMerge.type === 'send_form' && itemMerge.client_phone}
                                  {itemMerge.type === 'click' && `${itemMerge.campaign ? itemMerge.campaign : ''} ${itemMerge.campaign_id ? '(' + itemMerge.campaign_id + ')' : ''}`}

                                </div>
                                <div>
                                  {itemMerge.type === 'surf' && itemMerge.phone}
                                  {itemMerge.type === 'click_call' && itemMerge.phone}
                                  {itemMerge.type === 'promo' && itemMerge.phone}
                                  {
                                    itemMerge.type === 'order' &&
                                    getServiceRow(itemMerge.service_resource, itemMerge.zip)
                                  }
                                  {itemMerge.type === 'send_form' && itemMerge.phone}
                                  {itemMerge.type === 'visit' && itemMerge.phone}

                                  {
                                    itemMerge.type === 'click' && itemMerge.phone
                                  }

                                  {itemMerge.type === 'call' && itemMerge.phone &&
                                    ((selectedBackChain.id === item.id || selectedPhone.id === item.id) ?
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                        className={classNames({
                                          selected: itemMerge.id === nestedSelectedPhone.id
                                        })}
                                        onClick={() => reportData.permissions.conversion_edit_data && handleNestedPhone(itemMerge.phone, itemMerge.id, itemMerge.created_at)}
                                      >
                                        <Icon
                                          viewBox="0 0 16 16"
                                          style={{
                                            width: '28px',
                                            minWidth: '16px',
                                            height: '16px',
                                            marginRight: '5px',
                                            cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                            opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                          }}
                                          icon="download-1"
                                        />
                                        {itemMerge.phone}
                                      </div> : itemMerge.phone)
                                  }
                                </div>
                              </div>

                              <div
                                style={{
                                  position: 'relative'
                                }}
                                className="nested-item td"
                              >
                                {
                                  itemMerge.type === 'call' &&
                                  itemMerge.call_recording_url &&
                                  <div
                                    style={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    <button
                                      className="_zeroed _iconed"
                                      onClick={() => setCallListenPopup(itemMerge.call_recording_url)}
                                    >
                                      <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                    </button>
                                    <span style={{ marginLeft: '5px' }}> {itemMerge.call_duration} </span>
                                  </div>
                                }
                                <div>
                                  {itemMerge.type === 'send_form' && itemMerge.message}
                                  {itemMerge.type === 'click' && itemMerge.keyword}
                                  {itemMerge.type === 'order' && itemMerge.appliances_brands}
                                  {itemMerge.type === 'promo' && itemMerge.email}
                                </div>
                                <div>

                                  {itemMerge.type === 'surf' && itemMerge.client_time}
                                  {itemMerge.type === 'click_call' && itemMerge.client_time}
                                  {itemMerge.type === 'click' && itemMerge.ads}
                                  {/* {itemMerge.type === 'call' && itemMerge.back_chain} */}
                                  {/* {itemMerge.type === 'send_form' && itemMerge.back_chain} */}
                                  {itemMerge.type === 'promo' && itemMerge.back_chain}
                                  {itemMerge.type === 'order' && itemMerge.keyword}
                                </div>

                                {
                                  !!itemMerge.is_sent_conversion &&
                                  <Icon
                                    viewBox="0 0 1024 1024"
                                    style={{
                                      width: '16px',
                                      minWidth: '16px',
                                      height: '16px',
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px'
                                    }}
                                    icon="send-21"
                                  />
                                }
                              </div>
                            </div>

                            {/* nested filter */}
                            {
                              `${nestedFilterList.merge_id}` === `${itemMerge.id}` &&
                              nestedFilterList.data.map((itemNestedFilter, nestedFilterIndex) => (
                                <div
                                  key={nestedFilterIndex}
                                  className={classNames('tr nested-items nested-filter-items',
                                    {
                                      order: itemNestedFilter.type === 'order',
                                      call: itemNestedFilter.type === 'call',
                                      send_form: itemNestedFilter.type === 'send_form',
                                      visit: itemNestedFilter.type === 'visit',
                                      click_call: itemNestedFilter.type === 'click_call',
                                      click: itemNestedFilter.type === 'click',
                                      surf: itemNestedFilter.type === 'surf',
                                      promo: itemNestedFilter.type === 'promo'
                                    })}
                                >
                                  <div className="nested td" >
                                    <div className="indent">

                                    </div>

                                    <div className="nested-arrows">
                                      <span
                                        className="arrow-up"
                                        style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                        onClick={() => reportData.permissions.conversion_edit_data && setMoveMerge({
                                          mergeIdFrom: itemNestedFilter.merge_id,
                                          backChainFrom: item.back_chain,
                                          cidFrom: itemNestedFilter.cid,
                                          campaign: itemNestedFilter.campaign,
                                          campaign_id: itemNestedFilter.campaign_id,
                                          keyword: itemNestedFilter.keyword,
                                          id_field: itemNestedFilter.id,
                                          id_field_High: item.id,
                                          is_from_call: itemNestedFilter.type === 'call' ? itemNestedFilter.is_from_call : null,
                                          is_nested: false,
                                        })}
                                      ></span>
                                      <span
                                        className="arrow-down"
                                        style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                        onClick={() => reportData.permissions.conversion_edit_data && handleMoveDownButton(item.back_chain, itemNestedFilter.id)}
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="nested-item td" >
                                    <div> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, itemNestedFilter.created_at)} </div>
                                    <div>
                                      <p>
                                        {itemNestedFilter.type}
                                      </p>
                                      {
                                        itemNestedFilter.type === 'surf' && itemNestedFilter.surf_duration &&
                                        <p>
                                          ({itemNestedFilter.surf_duration})
                                        </p>
                                      }
                                    </div>
                                  </div>

                                  <div className="nested-item td" >
                                    <div>
                                      {itemNestedFilter.ip}
                                    </div>
                                    <div>
                                      {itemNestedFilter.type !== 'order' && itemNestedFilter.ip_isp}
                                      {
                                        itemNestedFilter.type === 'order' && itemNestedFilter.order_status && itemNestedFilter.order_status.toLowerCase() === 'canceled' &&
                                        <div className="order-status">
                                          <span>
                                            Canceled
                                          </span>
                                        </div>
                                      }
                                    </div>
                                  </div>

                                  <div className="nested-item td" >
                                    <div>
                                      {itemNestedFilter.type !== 'call' && itemNestedFilter.cid}
                                      {/* {itemMerge.type === 'click' && itemMerge.client_phone} */}
                                    </div>
                                    <div>
                                      {itemNestedFilter.merge_id}
                                    </div>
                                  </div>

                                  <div className="nested-item td" >
                                    <div>
                                      {itemNestedFilter.type !== 'order' && itemNestedFilter.type !== 'call' && itemNestedFilter.page}
                                      {itemNestedFilter.type === 'call' && itemNestedFilter.friendly_name}
                                      {itemNestedFilter.type === 'order' && itemNestedFilter.order}
                                    </div>
                                    <div>
                                      {itemNestedFilter.type !== 'order' && itemNestedFilter.type !== 'call' && itemNestedFilter.ip_geo}
                                      {itemNestedFilter.type === 'order' && itemNestedFilter.back_chain}
                                      {itemNestedFilter.type === 'call' && itemNestedFilter.back_chain}
                                    </div>
                                  </div>

                                  <div className="nested-item td" >
                                    <div>
                                      {itemNestedFilter.type === 'call' && itemNestedFilter.dispatcher}
                                      {itemNestedFilter.type !== 'order' && itemNestedFilter.type !== 'call' && itemNestedFilter.visitor_id}
                                      {itemNestedFilter.type === 'order' && itemNestedFilter.dispatcher}
                                      {/* {item.fifth_one} */}
                                    </div>
                                    <div>
                                      {itemNestedFilter.type === 'send_form' && itemNestedFilter.back_chain}
                                      {itemNestedFilter.type === 'call' && `${itemNestedFilter.call_direction ? itemNestedFilter.call_direction : ''} / ${itemNestedFilter.call_status ? itemNestedFilter.call_status : ''}`}
                                      {itemNestedFilter.type === 'order' && `${itemNestedFilter.campaign ? itemNestedFilter.campaign : ''} ${itemNestedFilter.campaign_id ? '(' + itemNestedFilter.campaign_id + ')' : ''}`}
                                      {itemNestedFilter.type === 'click' &&
                                        <p className="text-dots" title={itemNestedFilter.gclid} >
                                          {itemNestedFilter.gclid}
                                        </p>
                                      }
                                      {/* {item.fifth_two}  */}
                                    </div>
                                  </div>

                                  <div className="nested-item td" >
                                    <div>
                                      {itemNestedFilter.type === 'promo' && itemNestedFilter.client_phone}
                                      {itemNestedFilter.type === 'order' && itemNestedFilter.client_phone}
                                      {itemNestedFilter.type === 'call' && itemNestedFilter.client_phone}
                                      {itemNestedFilter.type === 'send_form' && itemNestedFilter.client_phone}
                                      {itemNestedFilter.type === 'click' && `${itemNestedFilter.campaign ? itemNestedFilter.campaign : ''} ${itemNestedFilter.campaign_id ? '(' + itemNestedFilter.campaign_id + ')' : ''}`}

                                    </div>
                                    <div>
                                      {itemNestedFilter.type === 'surf' && itemNestedFilter.phone}
                                      {itemNestedFilter.type === 'click_call' && itemNestedFilter.phone}
                                      {itemNestedFilter.type === 'promo' && itemNestedFilter.phone}
                                      {
                                        itemNestedFilter.type === 'order' &&
                                        getServiceRow(itemNestedFilter.service_resource, itemNestedFilter.zip)
                                      }
                                      {itemNestedFilter.type === 'send_form' && itemNestedFilter.phone}
                                      {itemNestedFilter.type === 'visit' && itemNestedFilter.phone}

                                      {
                                        itemNestedFilter.type === 'click' && itemNestedFilter.phone
                                      }

                                      {itemNestedFilter.type === 'call' && itemNestedFilter.phone &&
                                        ((selectedBackChain.id === item.id || selectedPhone.id === item.id) ?
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                            className={classNames({
                                              selected: itemNestedFilter.id === nestedSelectedPhone.id
                                            })}
                                            onClick={() => reportData.permissions.conversion_edit_data && handleNestedPhone(itemNestedFilter.phone, itemNestedFilter.id, itemNestedFilter.created_at)}
                                          >
                                            <Icon
                                              viewBox="0 0 16 16"
                                              style={{
                                                width: '28px',
                                                minWidth: '16px',
                                                height: '16px',
                                                marginRight: '5px',
                                                cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                                opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                              }}
                                              icon="download-1"
                                            />
                                            {itemNestedFilter.phone}
                                          </div> : itemNestedFilter.phone)
                                      }


                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      position: 'relative'
                                    }}
                                    className="nested-item td"
                                  >
                                    {
                                      itemMerge.type === 'call' &&
                                      itemMerge.call_recording_url &&
                                      <div
                                        style={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <button
                                          className="_zeroed _iconed"
                                          onClick={() => setCallListenPopup(itemMerge.call_recording_url)}
                                        >
                                          <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                        </button>
                                        <span style={{ marginLeft: '5px' }}> {itemMerge.call_duration} </span>
                                      </div>
                                    }
                                    <div>
                                      {itemMerge.type === 'send_form' && itemMerge.message}
                                      {itemMerge.type === 'click' && itemMerge.keyword}
                                      {itemMerge.type === 'order' && itemMerge.appliances_brands}
                                      {itemMerge.type === 'promo' && itemMerge.email}
                                    </div>
                                    <div>

                                      {itemMerge.type === 'surf' && itemMerge.client_time}
                                      {itemMerge.type === 'click_call' && itemMerge.client_time}
                                      {itemMerge.type === 'click' && itemMerge.ads}
                                      {/* {itemMerge.type === 'call' && itemMerge.back_chain} */}
                                      {/* {itemMerge.type === 'send_form' && itemMerge.back_chain} */}
                                      {itemMerge.type === 'promo' && itemMerge.back_chain}
                                      {itemMerge.type === 'order' && itemMerge.keyword}
                                    </div>

                                    {
                                      !!itemMerge.is_sent_conversion &&
                                      <Icon
                                        viewBox="0 0 1024 1024"
                                        style={{
                                          width: '16px',
                                          minWidth: '16px',
                                          height: '16px',
                                          position: 'absolute',
                                          top: '10px',
                                          right: '10px'
                                        }}
                                        icon="send-21"
                                      />
                                    }
                                  </div>
                                </div>
                              ))
                            }

                            {/* nested Phone table */}
                            {
                              item.type === 'order' &&
                              (nestedSelectedPhone.id === itemMerge.id) &&
                              nestedList.map((itemNested, idx) => (
                                <React.Fragment
                                  key={idx}
                                >
                                  <div
                                    className={classNames('tr nested-items',
                                      {
                                        order: itemNested.type === 'order',
                                        call: itemNested.type === 'call',
                                        send_form: itemNested.type === 'send_form',
                                        visit: itemNested.type === 'visit',
                                        click_call: itemNested.type === 'click_call',
                                        click: itemNested.type === 'click',
                                        surf: itemNested.type === 'surf',
                                        promo: itemNested.type === 'promo'
                                      })}
                                  >
                                    <div
                                      className="nested nested-phone td"
                                      style={{ alignItems: 'flex-end' }}
                                    >
                                      <span
                                        className="arrow-up"
                                        style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                        onClick={() => reportData.permissions.conversion_edit_data && setMoveMerge({
                                          mergeIdFrom: itemNested.merge_id,
                                          backChainFrom: item.back_chain,
                                          cidFrom: itemNested.cid,
                                          campaign: itemNested.campaign,
                                          campaign_id: itemNested.campaign_id,
                                          keyword: itemNested.keyword,
                                          id_field: itemNested.id,
                                          id_field_High: item.id,
                                          is_from_call: null,
                                          is_nested: true,
                                        })}
                                      ></span>
                                    </div>
                                    <div className="nested-item td" >
                                      <div> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, itemNested.created_at)} </div>
                                      <div>
                                        <p>
                                          {itemNested.type}
                                        </p>
                                        {
                                          itemNested.type === 'surf' && itemNested.surf_duration &&
                                          <p>
                                            ({itemNested.surf_duration})
                                          </p>
                                        }
                                      </div>
                                    </div>

                                    <div className="nested-item td" >
                                      {
                                        itemNested.ip &&
                                        <div
                                          className={classNames({
                                            __bold: `${itemNested.id}` === `${nestedFilterDownList.merge_id}` && itemNested.ip === nestedFilterDownList.value
                                          })}
                                          onClick={() => itemNested.ip && handleGetNestedDataWithFilter('ip', itemNested.ip, `${itemNested.id}`, '')}
                                        >
                                          {itemNested.ip}
                                        </div>
                                      }

                                      <div> {itemNested.ip_isp} </div>
                                    </div>

                                    <div className="nested-item td" >
                                      {
                                        itemNested.type !== 'call' && itemNested.type !== 'click' && itemNested.cid &&
                                        <div
                                          className={classNames({
                                            __bold: `${itemNested.id}` === `${nestedFilterDownList.merge_id}` &&
                                              itemNested.type !== 'call' && itemNested.cid &&
                                              itemNested.cid === nestedFilterDownList.value
                                          })}
                                          onClick={() => itemNested.type !== 'call' && itemNested.cid && handleGetNestedDataWithFilter('cid', itemNested.cid, `${itemNested.id}`, '')}
                                        >
                                          {itemNested.type !== 'call' && itemNested.type !== 'click' && itemNested.cid}
                                        </div>
                                      }

                                      {
                                        itemNested.type === 'click' &&
                                        <div>
                                          {itemNested.type === 'click' && itemNested.client_phone}
                                        </div>
                                      }
                                      <div>
                                        {itemNested.merge_id}
                                      </div>
                                    </div>

                                    <div className="nested-item td" >
                                      <div>
                                        {itemNested.type !== 'order' && itemNested.type !== 'call' && itemNested.page}
                                        {itemNested.type === 'call' && itemNested.friendly_name}
                                        {itemNested.type === 'order' && itemNested.order}
                                      </div>
                                      <div>
                                        {itemNested.type !== 'order' && itemNested.type !== 'call' && itemNested.ip_geo}
                                        {itemNested.type === 'order' && itemNested.back_chain}
                                        {itemNested.type === 'call' && itemNested.back_chain}
                                      </div>
                                    </div>

                                    <div className="nested-item td" >
                                      {
                                        itemNested.type !== 'order' && itemNested.type !== 'call' && itemNested.visitor_id &&
                                        <div
                                          className={classNames({
                                            __bold: `${itemNested.id}` === `${nestedFilterDownList.merge_id}` && itemNested.visitor_id === nestedFilterDownList.value
                                          })}
                                          onClick={() => itemNested.ip && handleGetNestedDataWithFilter('visitor_id', itemNested.visitor_id, `${itemNested.id}`, '')}
                                        >
                                          {itemNested.visitor_id}
                                        </div>
                                      }

                                      <div>
                                        {itemNested.type === 'call' && itemNested.dispatcher}
                                        {itemNested.type === 'order' && itemNested.dispatcher}
                                        {/* {item.fifth_one} */}
                                      </div>
                                      <div>
                                        {itemNested.type === 'send_form' && itemNested.back_chain}
                                        {itemNested.type === 'call' && `${itemNested.call_direction ? itemNested.call_direction : ''} / ${itemNested.call_status ? itemNested.call_status : ''}`}
                                        {itemNested.type === 'order' && `${itemNested.campaign ? itemNested.campaign : ''} ${itemNested.campaign_id ? '(' + itemNested.campaign_id + ')' : ''}`}
                                        {itemNested.type === 'click' &&
                                          <p className="text-dots" title={itemNested.gclid} >
                                            {itemNested.gclid}
                                          </p>
                                        }
                                        {/* {item.fifth_two}  */}
                                      </div>
                                    </div>

                                    <div className="nested-item td" >
                                      <div>
                                        {itemNested.type === 'promo' && itemNested.client_phone}
                                        {itemNested.type === 'order' && itemNested.client_phone}
                                        {itemNested.type === 'call' && itemNested.client_phone}
                                        {itemNested.type === 'send_form' && itemNested.client_phone}
                                        {itemNested.type === 'click' && `${itemNested.campaign ? itemNested.campaign : ''} ${itemNested.campaign_id ? '(' + itemNested.campaign_id + ')' : ''}`}

                                      </div>
                                      <div>
                                        {itemNested.type === 'surf' && itemNested.phone}
                                        {itemNested.type === 'click_call' && itemNested.phone}
                                        {itemNested.type === 'promo' && itemNested.phone}
                                        {
                                          itemNested.type === 'order' &&
                                          getServiceRow(itemNested.service_resource, itemNested.zip)
                                        }
                                        {itemNested.type === 'send_form' && itemNested.phone}
                                        {itemNested.type === 'visit' && itemNested.phone}

                                        {itemNested.type === 'click' && itemNested.phone
                                        }

                                        {itemNested.type === 'call' && itemNested.phone}
                                      </div>
                                    </div>

                                    <div
                                      className="nested-item td"
                                      style={{ position: 'relative' }}
                                    >
                                      {
                                        itemNested.type === 'call' &&
                                        itemNested.call_recording_url &&
                                        <div
                                          style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <button
                                            className="_zeroed _iconed"
                                            onClick={() => setCallListenPopup(itemNested.call_recording_url)}
                                          >
                                            <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                          </button>
                                          <span style={{ marginLeft: '5px' }}> {itemNested.call_duration} </span>
                                        </div>
                                      }
                                      <div>
                                        {itemNested.type === 'send_form' && itemNested.message}
                                        {itemNested.type === 'click' && itemNested.keyword}
                                        {itemNested.type === 'order' && itemNested.appliances_brands}
                                        {itemNested.type === 'promo' && itemNested.email}
                                      </div>
                                      <div>

                                        {itemNested.type === 'surf' && itemNested.client_time}
                                        {itemNested.type === 'click_call' && itemNested.client_time}
                                        {itemNested.type === 'click' && itemNested.ads}
                                        {/* {itemNested.type === 'call' && itemNested.back_chain} */}
                                        {/* {itemNested.type === 'send_form' && itemNested.back_chain} */}
                                        {itemNested.type === 'promo' && itemNested.back_chain}
                                        {itemNested.type === 'order' && itemNested.keyword}
                                      </div>
                                      {
                                        !!itemNested.is_sent_conversion &&
                                        <Icon
                                          viewBox="0 0 1024 1024"
                                          style={{
                                            width: '16px',
                                            minWidth: '16px',
                                            height: '16px',
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px'
                                          }}
                                          icon="send-21"
                                        />
                                      }
                                    </div>
                                  </div>

                                  {
                                    `${nestedFilterDownList.merge_id}` === `${itemNested.id}` &&
                                    nestedFilterDownList.data.map((itemNestedFilter, nestedFilterIndex) => (
                                      <div
                                        key={nestedFilterIndex}
                                        className={classNames('tr nested-items nested-filter-items',
                                          {
                                            order: itemNestedFilter.type === 'order',
                                            call: itemNestedFilter.type === 'call',
                                            send_form: itemNestedFilter.type === 'send_form',
                                            visit: itemNestedFilter.type === 'visit',
                                            click_call: itemNestedFilter.type === 'click_call',
                                            click: itemNestedFilter.type === 'click',
                                            surf: itemNestedFilter.type === 'surf',
                                            promo: itemNestedFilter.type === 'promo'
                                          })}
                                      >
                                        <div className="nested td" >
                                          <div className="indent">

                                          </div>

                                          <div className="nested-arrows">
                                            <span
                                              className="arrow-up"
                                              style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                              onClick={() => reportData.permissions.conversion_edit_data && setMoveMerge({
                                                mergeIdFrom: itemNestedFilter.merge_id,
                                                backChainFrom: item.back_chain,
                                                cidFrom: itemNestedFilter.cid,
                                                campaign: itemNestedFilter.campaign,
                                                campaign_id: itemNestedFilter.campaign_id,
                                                keyword: itemNestedFilter.keyword,
                                                id_field: itemNestedFilter.id,
                                                id_field_High: item.id,
                                                is_from_call: itemNestedFilter.type === 'call' ? itemNestedFilter.is_from_call : null,
                                                is_nested: false,
                                              })}
                                            ></span>
                                            <span
                                              className="arrow-down"
                                              style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                              onClick={() => reportData.permissions.conversion_edit_data && handleMoveDownButton(item.back_chain, itemNestedFilter.id)}
                                            ></span>
                                          </div>
                                        </div>
                                        <div className="nested-item td" >
                                          <div> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, itemNestedFilter.created_at)} </div>
                                          <div>
                                            <p>
                                              {itemNestedFilter.type}
                                            </p>
                                            {
                                              itemNestedFilter.type === 'surf' && itemNestedFilter.surf_duration &&
                                              <p>
                                                ({itemNestedFilter.surf_duration})
                                              </p>
                                            }
                                          </div>
                                        </div>

                                        <div className="nested-item td" >
                                          <div>
                                            {itemNestedFilter.ip}
                                          </div>
                                          <div>
                                            {itemNestedFilter.type !== 'order' && itemNestedFilter.ip_isp}
                                            {
                                              itemNestedFilter.type === 'order' && itemNestedFilter.order_status && itemNestedFilter.order_status.toLowerCase() === 'canceled' &&
                                              <div className="order-status">
                                                <span>
                                                  Canceled
                                                </span>
                                              </div>
                                            }
                                          </div>
                                        </div>

                                        <div className="nested-item td" >
                                          <div>
                                            {itemNestedFilter.type !== 'call' && itemNestedFilter.cid}
                                            {/* {itemMerge.type === 'click' && itemMerge.client_phone} */}
                                          </div>
                                          <div>
                                            {itemNestedFilter.merge_id}
                                          </div>
                                        </div>

                                        <div className="nested-item td" >
                                          <div>
                                            {itemNestedFilter.type !== 'order' && itemNestedFilter.type !== 'call' && itemNestedFilter.page}
                                            {itemNestedFilter.type === 'call' && itemNestedFilter.friendly_name}
                                            {itemNestedFilter.type === 'order' && itemNestedFilter.order}
                                          </div>
                                          <div>
                                            {itemNestedFilter.type !== 'order' && itemNestedFilter.type !== 'call' && itemNestedFilter.ip_geo}
                                            {itemNestedFilter.type === 'order' && itemNestedFilter.back_chain}
                                            {itemNestedFilter.type === 'call' && itemNestedFilter.back_chain}
                                          </div>
                                        </div>

                                        <div className="nested-item td" >
                                          <div>
                                            {itemNestedFilter.type === 'call' && itemNestedFilter.dispatcher}
                                            {itemNestedFilter.type !== 'order' && itemNestedFilter.type !== 'call' && itemNestedFilter.visitor_id}
                                            {itemNestedFilter.type === 'order' && itemNestedFilter.dispatcher}
                                            {/* {item.fifth_one} */}
                                          </div>
                                          <div>
                                            {itemNestedFilter.type === 'send_form' && itemNestedFilter.back_chain}
                                            {itemNestedFilter.type === 'call' && `${itemNestedFilter.call_direction ? itemNestedFilter.call_direction : ''} / ${itemNestedFilter.call_status ? itemNestedFilter.call_status : ''}`}
                                            {itemNestedFilter.type === 'order' && `${itemNestedFilter.campaign ? itemNestedFilter.campaign : ''} ${itemNestedFilter.campaign_id ? '(' + itemNestedFilter.campaign_id + ')' : ''}`}
                                            {itemNestedFilter.type === 'click' &&
                                              <p className="text-dots" title={itemNestedFilter.gclid} >
                                                {itemNestedFilter.gclid}
                                              </p>
                                            }
                                            {/* {item.fifth_two}  */}
                                          </div>
                                        </div>

                                        <div className="nested-item td" >
                                          <div>
                                            {itemNestedFilter.type === 'promo' && itemNestedFilter.client_phone}
                                            {itemNestedFilter.type === 'order' && itemNestedFilter.client_phone}
                                            {itemNestedFilter.type === 'call' && itemNestedFilter.client_phone}
                                            {itemNestedFilter.type === 'send_form' && itemNestedFilter.client_phone}
                                            {itemNestedFilter.type === 'click' && `${itemNestedFilter.campaign ? itemNestedFilter.campaign : ''} ${itemNestedFilter.campaign_id ? '(' + itemNestedFilter.campaign_id + ')' : ''}`}

                                          </div>
                                          <div>
                                            {itemNestedFilter.type === 'surf' && itemNestedFilter.phone}
                                            {itemNestedFilter.type === 'click_call' && itemNestedFilter.phone}
                                            {itemNestedFilter.type === 'promo' && itemNestedFilter.phone}
                                            {
                                              itemNestedFilter.type === 'order' &&
                                              getServiceRow(itemNestedFilter.service_resource, itemNestedFilter.zip)
                                            }
                                            {itemNestedFilter.type === 'send_form' && itemNestedFilter.phone}
                                            {itemNestedFilter.type === 'visit' && itemNestedFilter.phone}

                                            {
                                              itemNestedFilter.type === 'click' && itemNestedFilter.phone
                                            }

                                            {itemNestedFilter.type === 'call' && itemNestedFilter.phone &&
                                              ((selectedBackChain.id === item.id || selectedPhone.id === item.id) ?
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                  }}
                                                  className={classNames({
                                                    selected: itemNestedFilter.id === nestedSelectedPhone.id
                                                  })}
                                                  onClick={() => reportData.permissions.conversion_edit_data && handleNestedPhone(itemNestedFilter.phone, itemNestedFilter.id, itemNestedFilter.created_at)}
                                                >
                                                  <Icon
                                                    viewBox="0 0 16 16"
                                                    style={{
                                                      width: '28px',
                                                      minWidth: '16px',
                                                      height: '16px',
                                                      marginRight: '5px',
                                                      cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                                      opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                                    }}
                                                    icon="download-1"
                                                  />
                                                  {itemNestedFilter.phone}
                                                </div> : itemNestedFilter.phone)
                                            }


                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            position: 'relative'
                                          }}
                                          className="nested-item td"
                                        >
                                          {
                                            itemMerge.type === 'call' &&
                                            itemMerge.call_recording_url &&
                                            <div
                                              style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                              <button
                                                className="_zeroed _iconed"
                                                onClick={() => setCallListenPopup(itemMerge.call_recording_url)}
                                              >
                                                <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                              </button>
                                              <span style={{ marginLeft: '5px' }}> {itemMerge.call_duration} </span>
                                            </div>
                                          }
                                          <div>
                                            {itemMerge.type === 'send_form' && itemMerge.message}
                                            {itemMerge.type === 'click' && itemMerge.keyword}
                                            {itemMerge.type === 'order' && itemMerge.appliances_brands}
                                            {itemMerge.type === 'promo' && itemMerge.email}
                                          </div>
                                          <div>

                                            {itemMerge.type === 'surf' && itemMerge.client_time}
                                            {itemMerge.type === 'click_call' && itemMerge.client_time}
                                            {itemMerge.type === 'click' && itemMerge.ads}
                                            {/* {itemMerge.type === 'call' && itemMerge.back_chain} */}
                                            {/* {itemMerge.type === 'send_form' && itemMerge.back_chain} */}
                                            {itemMerge.type === 'promo' && itemMerge.back_chain}
                                            {itemMerge.type === 'order' && itemMerge.keyword}
                                          </div>

                                          {
                                            !!itemMerge.is_sent_conversion &&
                                            <Icon
                                              viewBox="0 0 1024 1024"
                                              style={{
                                                width: '16px',
                                                minWidth: '16px',
                                                height: '16px',
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px'
                                              }}
                                              icon="send-21"
                                            />
                                          }
                                        </div>
                                      </div>
                                    ))
                                  }
                                </React.Fragment>
                              ))
                            }
                          </React.Fragment>
                        ))
                      }

                    </React.Fragment>
                  ))
                }
              </div>

              {/* Mobile table items */}
              <div className={classNames('mobile-table-items', '__show-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}>
                {
                  reportData?.conversions.map((item, index) => (
                    <React.Fragment key={index}>
                      <div
                        key={index}
                        className={classNames('item item-tr',
                          {
                            order: item.type === 'order',
                            call: item.type === 'call',
                            send_form: item.type === 'send_form',
                            visit: item.type === 'visit',
                            click_call: item.type === 'click_call',
                            click: item.type === 'click',
                            surf: item.type === 'surf',
                            promo: item.type === 'promo'
                          })}
                      >
                        <div className="__top">
                          <div className="__left">
                            <div>
                              {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at)}
                            </div>

                            <div> {item.type !== 'order' && item.ip} </div>

                            {
                              item.type === 'order' &&
                              <div
                                className="indicator-block"
                                onClick={() => reportData.permissions.conversion_edit_data && setMoveOrderUnset(item.id)}
                              >
                                <span
                                  className={classNames({
                                    conversion: conversionsView === 'google' ? item.is_conversion : item.is_conversion_fb,
                                    sent: conversionsView === 'google' ? item.is_sent_conversion : item.is_sent_conversion_fb,
                                    call: item.is_from_call,
                                    unsent: conversionsView === 'google' ? item.is_unsent : item.is_unsent_fb,
                                  })}
                                >
                                  Canceled
                                </span>
                              </div>
                            }

                            <div>
                              {item.type !== 'order' && item.ip_isp}
                              {
                                item.type === 'order' && item.order_status && item.order_status.toLowerCase() === 'canceled' &&
                                <div className="order-status">
                                  <span>
                                    Canceled
                                  </span>
                                </div>
                              }
                            </div>

                            {
                              item.type === 'order' ?
                                item.cid ?
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                    onClick={() => reportData.permissions.conversion_send && handleCidPopup(item.cid, item.id, item.merge_id)}
                                  >
                                    <span className="gray">Cid:</span>
                                    <Icon
                                      viewBox="0 0 1024 1024"
                                      style={{
                                        width: '16px',
                                        minWidth: '16px',
                                        height: '16px',
                                        marginRight: '5px',
                                        cursor: 'pointer',
                                        opacity: reportData.permissions.conversion_send ? .5 : .2
                                      }}
                                      icon="send-21"
                                    />
                                    {item.cid}
                                  </div> :
                                  <div
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <span className="gray">Cid:</span>
                                    N/A
                                  </div> :
                                null
                            }

                            {item.type === 'order' ?
                              item.merge_id ?
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                  className={classNames({
                                    selected: item.id === selectedMerge.id
                                  })}
                                  onClick={() => reportData.permissions.conversion_edit_data && handleMerge(item.merge_id, item.id)}
                                >
                                  <span className="gray">Merge:</span>
                                  <Icon
                                    viewBox="0 0 16 16"
                                    style={{
                                      width: '16px',
                                      minWidth: '16px',
                                      height: '16px',
                                      margin: '0 5px 3px 5px',
                                      cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                      opacity: reportData.permissions.conversion_edit_data ? .5 : .2,
                                    }}
                                    icon="download-1"
                                  />
                                  {item.merge_id}
                                </div> :
                                item.merge_id :
                              item.merge_id}

                            {item.type === 'order' && item.back_chain &&
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                                className={classNames({
                                  selected: item.id === selectedBackChain.id
                                })}
                                onClick={() => reportData.permissions.conversion_edit_data && handleBackChain(item.back_chain, item.id)}
                              >
                                <span className="gray">BackChain:</span>
                                <Icon
                                  viewBox="0 0 16 16"
                                  style={{
                                    width: '16px',
                                    minWidth: '16px',
                                    height: '16px',
                                    margin: '0 5px 3px 5px',
                                    cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                    opacity: reportData.permissions.conversion_edit_data ? .5 : .2,
                                  }}
                                  icon="download-1"
                                />
                                {item.back_chain}
                              </div>
                            }

                          </div>

                          <div style={{ marginLeft: '10px', overflow: 'hidden' }} className="__right">
                            <div>
                              {item.type !== 'order' && item.type !== 'call' && item.type !== 'click' && item.cid}
                              {item.type === 'click' && item.client_phone}
                            </div>
                            <div>
                              {item.type !== 'order' && item.type !== 'call' && item.page}
                              {item.type === 'call' && item.friendly_name}
                              {item.type === 'order' && item.order}
                            </div>
                            <div>
                              {item.type !== 'order' && item.type !== 'call' && item.ip_geo}
                              {item.type === 'call' && item.back_chain}
                              {item.type === 'order' && item.back_chain &&
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-end'
                                  }}
                                  className={classNames({
                                    selected: item.id === selectedBackChain.id
                                  })}
                                  onClick={() => !loading && reportData.permissions.conversion_edit_data && handleBackChain(item.back_chain, item.id)}
                                >
                                  <Icon
                                    viewBox="0 0 16 16"
                                    style={{
                                      width: '16px',
                                      minWidth: '16px',
                                      height: '16px',
                                      marginRight: '5px',
                                      cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                      opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                    }}
                                    icon="download-1"
                                  />
                                  {item.back_chain}
                                </div>
                              }
                            </div>

                            <div>
                              {item.type === 'call' && item.dispatcher}
                              {item.type !== 'call' && item.type !== 'order' && item.visitor_id}
                              {item.type === 'order' && item.dispatcher}
                              {/* {item.fifth_one} */}
                            </div>
                            <div>
                              {item.type === 'send_form' && item.back_chain}
                              {item.type === 'call' && `${item.call_direction ? item.call_direction : ''} / ${item.call_status ? item.call_status : ''}`}
                              {item.type === 'order' && `${item.campaign ? item.campaign : ''} ${item.campaign_id ? '(' + item.campaign_id + ')' : ''}`}
                              {/* {item.fifth_two}  */}
                            </div>
                          </div>
                        </div>

                        <div className="__bottom">
                          <div className="__left">
                            <div>{item.type}</div>
                            {
                              item.type === 'surf' && item.surf_duration &&
                              <div>
                                ({item.surf_duration})
                              </div>
                            }
                          </div>

                          <div style={{ marginLeft: '10px', overflow: 'hidden' }} className="__right">
                            <div>
                              {item.type === 'promo' && item.client_phone}
                              {item.type === 'call' && item.client_phone}
                              {item.type === 'send_form' && item.client_phone}
                              {item.type === 'click' && `${item.campaign ? item.campaign : ''} ${item.campaign_id ? '(' + item.campaign_id + ')' : ''}`}

                              {item.type === 'order' &&
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-end'
                                  }}
                                  className={classNames({
                                    selected: item.id === selectedPhone.id
                                  })}
                                  onClick={() => reportData.permissions.conversion_edit_data && handlePhone(item.created_at, dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.created_at), item.id, item.client_phone)}
                                >
                                  <Icon
                                    viewBox="0 0 16 16"
                                    style={{
                                      width: '28px',
                                      minWidth: '16px',
                                      height: '16px',
                                      marginRight: '5px',
                                      cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                      opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                    }}
                                    icon="download-1"
                                  />
                                  {item.client_phone}
                                </div>
                              }

                            </div>
                            <div>
                              {item.type === 'surf' && item.phone}
                              {item.type === 'call' && item.phone}
                              {item.type === 'click_call' && item.phone}
                              {item.type === 'promo' && item.phone}
                              {
                                item.type === 'order' &&
                                getServiceRow(item.service_resource, item.zip)
                              }
                              {item.type === 'click' &&
                                <p className="text-dots" title={item.gclid} >
                                  {item.gclid}
                                </p>
                              }
                            </div>

                            <div>
                              {item.type === 'send_form' && item.message}
                              {item.type === 'click' && item.keyword}
                              {item.type === 'order' && item.appliances_brands}
                              {item.type === 'promo' && item.email}
                            </div>

                            {
                              item.type === 'call' &&
                              item.call_recording_url &&
                              <div
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <button
                                  className="_zeroed _iconed"
                                  onClick={() => setCallListenPopup(item.call_recording_url)}
                                >
                                  <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                </button>
                                <span style={{ marginLeft: '5px' }}> {item.call_duration} </span>
                              </div>
                            }

                            <div>

                              {item.type === 'surf' && item.client_time}
                              {item.type === 'click_call' && item.client_time}
                              {item.type === 'click' && item.ads}
                              {/* {item.type === 'call' && item.back_chain} */}
                              {/* {item.type === 'send_form' && item.back_chain} */}
                              {item.type === 'promo' && item.back_chain}
                              {item.type === 'order' && item.keyword}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* nested empty */}
                      {
                        item.type === 'order' &&
                        (selectedBackChain.id === item.id || selectedPhone.id === item.id) &&
                        !mergeList.length &&
                        !loading &&
                        <div
                          className="tr nested-items order"
                        >
                          <div className="nested td" >
                            <Icon
                              viewBox="0 0 1024 1024"
                              style={{
                                width: '16px',
                                minWidth: '16px',
                                height: '16px',
                                marginRight: '5px'
                              }}
                              icon="share-65"
                            />
                          </div>
                          <div className="nested-empty">
                          </div>
                        </div>
                      }

                      {
                        item.type === 'order' &&
                        (selectedMerge.id === item.id || selectedBackChain.id === item.id || selectedPhone.id === item.id) &&
                        mergeList.map((itemMerge, idx) => (
                          <React.Fragment key={idx}>
                            <div
                              key={idx}
                              className={classNames('item item-tr',
                                {
                                  order: itemMerge.type === 'order',
                                  call: itemMerge.type === 'call',
                                  send_form: itemMerge.type === 'send_form',
                                  visit: itemMerge.type === 'visit',
                                  click_call: itemMerge.type === 'click_call',
                                  click: itemMerge.type === 'click',
                                  surf: itemMerge.type === 'surf',
                                  promo: itemMerge.type === 'promo'
                                })}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <div style={{ width: '5%' }} className="nested td" >
                                  <span
                                    className="arrow-up"
                                    style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                    onClick={() => reportData.permissions.conversion_edit_data && setMoveMerge({
                                      mergeIdFrom: itemMerge.merge_id,
                                      backChainFrom: item.back_chain,
                                      cidFrom: itemMerge.cid,
                                      campaign: itemMerge.campaign,
                                      campaign_id: itemMerge.campaign_id,
                                      keyword: itemMerge.keyword,
                                      id_field: itemMerge.id,
                                      id_field_High: item.id,
                                      is_from_call: itemMerge.type === 'call' ? itemMerge.is_from_call : null,
                                      is_nested: false,
                                    })}
                                  ></span>
                                  <span
                                    className="arrow-down"
                                    style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                    onClick={() => reportData.permissions.conversion_edit_data && handleMoveDownButton(item.back_chain, itemMerge.id)}
                                  ></span>
                                </div>

                                <div style={{ width: '90%' }}>
                                  <div className="__top">
                                    <div className="__left">
                                      <div>
                                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, itemMerge.created_at)}
                                      </div>

                                      <div
                                        className={classNames({
                                          __bold: `${itemMerge.id}` === `${nestedFilterList.merge_id}` && itemMerge.ip === nestedFilterList.value
                                        })}
                                        onClick={() => itemMerge.ip && handleGetDataWithFilter('ip', itemMerge.ip, `${itemMerge.id}`, '')}
                                      >
                                        {itemMerge.ip}
                                      </div>

                                      <div> {itemMerge.type !== 'order' && itemMerge.ip_isp} </div>

                                      {
                                        itemMerge.type === 'order' && itemMerge.order_status && itemMerge.order_status.toLowerCase() === 'canceled' &&
                                        <div className="order-status">
                                          <span>
                                            Canceled
                                          </span>
                                        </div>
                                      }

                                    </div>

                                    <div className="__right">
                                      <div
                                        className={classNames({
                                          __bold: `${itemMerge.id}` === `${nestedFilterList.merge_id}` &&
                                            itemMerge.type !== 'call' && itemMerge.cid &&
                                            itemMerge.cid === nestedFilterList.value
                                        })}
                                        onClick={() => itemMerge.type !== 'call' && itemMerge.cid && handleGetDataWithFilter('cid', itemMerge.cid, `${itemMerge.id}`, '')}
                                      >
                                        {itemMerge.type !== 'call' && itemMerge.cid}
                                        {/* {itemMerge.type === 'click' && itemMerge.client_phone} */}
                                      </div>
                                      <div>
                                        {itemMerge.merge_id}
                                      </div>
                                      <div>
                                        {itemMerge.type !== 'order' && itemMerge.type !== 'call' && itemMerge.page}
                                        {itemMerge.type === 'call' && itemMerge.friendly_name}
                                        {itemMerge.type === 'order' && itemMerge.order}
                                      </div>
                                      <div>
                                        {itemMerge.type !== 'order' && itemMerge.type !== 'call' && itemMerge.ip_geo}
                                        {itemMerge.type === 'order' && itemMerge.back_chain}
                                        {itemMerge.type === 'call' && itemMerge.back_chain}
                                      </div>

                                      <div
                                        className={classNames({
                                          __bold: `${itemMerge.id}` === `${nestedFilterList.merge_id}` &&
                                            itemMerge.type !== 'order' &&
                                            itemMerge.type !== 'call' &&
                                            itemMerge.visitor_id && itemMerge.visitor_id === nestedFilterList.value
                                        })}
                                        onClick={() => itemMerge.type !== 'order' &&
                                          itemMerge.type !== 'call' &&
                                          itemMerge.visitor_id &&
                                          handleGetDataWithFilter('visitor_id', itemMerge.visitor_id, `${itemMerge.id}`, '')}
                                      >
                                        {itemMerge.type === 'call' && itemMerge.dispatcher}
                                        {itemMerge.type !== 'order' && itemMerge.type !== 'call' && itemMerge.visitor_id}
                                        {itemMerge.type === 'order' && itemMerge.dispatcher}
                                        {/* {item.fifth_one} */}
                                      </div>
                                      <div>
                                        {itemMerge.type === 'send_form' && itemMerge.back_chain}
                                        {itemMerge.type === 'call' && `${itemMerge.call_direction ? itemMerge.call_direction : ''} / ${itemMerge.call_status ? itemMerge.call_status : ''}`}
                                        {itemMerge.type === 'order' && `${itemMerge.campaign ? itemMerge.campaign : ''} ${itemMerge.campaign_id ? '(' + itemMerge.campaign_id + ')' : ''}`}
                                        {/* {item.fifth_two}  */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="__bottom">
                                    <div className="__left">
                                      <div>{itemMerge.type}</div>
                                      {
                                        itemMerge.type === 'surf' && itemMerge.surf_duration &&
                                        <div>
                                          ({itemMerge.surf_duration})
                                        </div>
                                      }
                                    </div>

                                    <div style={{ marginLeft: '10px', overflow: 'hidden' }} className="__right">
                                      <div>
                                        {itemMerge.type === 'promo' && itemMerge.client_phone}
                                        {itemMerge.type === 'order' && itemMerge.client_phone}
                                        {itemMerge.type === 'call' && itemMerge.client_phone}
                                        {itemMerge.type === 'send_form' && itemMerge.client_phone}
                                        {itemMerge.type === 'click' && `${itemMerge.campaign ? itemMerge.campaign : ''} ${itemMerge.campaign_id ? '(' + itemMerge.campaign_id + ')' : ''}`}

                                      </div>
                                      <div>
                                        {itemMerge.type === 'surf' && itemMerge.phone}
                                        {itemMerge.type === 'click_call' && itemMerge.phone}
                                        {itemMerge.type === 'promo' && itemMerge.phone}
                                        {
                                          itemMerge.type === 'order' &&
                                          getServiceRow(itemMerge.service_resource, itemMerge.zip)
                                        }

                                        {itemMerge.type === 'click' &&
                                          <p className="text-dots" title={itemMerge.gclid} >
                                            {itemMerge.gclid}
                                          </p>
                                        }

                                        {itemMerge.type === 'call' && itemMerge.phone &&
                                          ((selectedBackChain.id === item.id || selectedPhone.id === item.id) ?
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                              }}
                                              className={classNames({
                                                selected: itemMerge.id === nestedSelectedPhone.id
                                              })}
                                              onClick={() => reportData.permissions.conversion_edit_data && handleNestedPhone(itemMerge.phone, itemMerge.id, itemMerge.created_at)}
                                            >
                                              <Icon
                                                viewBox="0 0 16 16"
                                                style={{
                                                  width: '28px',
                                                  minWidth: '16px',
                                                  height: '16px',
                                                  marginRight: '5px',
                                                  cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                                  opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                                }}
                                                icon="download-1"
                                              />
                                              {itemMerge.phone}
                                            </div> : itemMerge.phone)
                                        }
                                      </div>
                                      <div>
                                        {itemMerge.type === 'send_form' && itemMerge.message}
                                        {itemMerge.type === 'click' && itemMerge.keyword}
                                        {itemMerge.type === 'order' && itemMerge.appliances_brands}
                                        {itemMerge.type === 'promo' && itemMerge.email}
                                      </div>
                                      {
                                        itemMerge.type === 'call' &&
                                        itemMerge.call_recording_url &&
                                        <div
                                          style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <button
                                            className="_zeroed _iconed"
                                            onClick={() => setCallListenPopup(itemMerge.call_recording_url)}
                                          >
                                            <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                          </button>
                                          <span style={{ marginLeft: '5px' }}> {itemMerge.call_duration} </span>
                                        </div>
                                      }
                                      <div style={{ position: 'relative' }}>

                                        {itemMerge.type === 'surf' && itemMerge.client_time}
                                        {itemMerge.type === 'click_call' && itemMerge.client_time}
                                        {itemMerge.type === 'click' && itemMerge.ads}
                                        {/* {itemMerge.type === 'call' && itemMerge.back_chain} */}
                                        {/* {itemMerge.type === 'send_form' && itemMerge.back_chain} */}
                                        {itemMerge.type === 'promo' && itemMerge.back_chain}
                                        {itemMerge.type === 'order' && itemMerge.keyword}

                                        {
                                          !!itemMerge.is_sent_conversion &&
                                          <Icon
                                            viewBox="0 0 1024 1024"
                                            style={{
                                              width: '16px',
                                              minWidth: '16px',
                                              height: '16px',
                                              position: 'absolute',
                                              top: '10px',
                                              right: '10px'
                                            }}
                                            icon="send-21"
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* merge filter block */}

                            {
                              `${nestedFilterList.merge_id}` === `${itemMerge.id}` &&
                              nestedFilterList.data.map((nestedItemFilter, nestedFilterIdx) => (
                                <div
                                  key={nestedFilterIdx}
                                  className={classNames('item item-tr item-tr-filter',
                                    {
                                      order: nestedItemFilter.type === 'order',
                                      call: nestedItemFilter.type === 'call',
                                      send_form: nestedItemFilter.type === 'send_form',
                                      visit: nestedItemFilter.type === 'visit',
                                      click_call: nestedItemFilter.type === 'click_call',
                                      click: nestedItemFilter.type === 'click',
                                      surf: nestedItemFilter.type === 'surf',
                                      promo: nestedItemFilter.type === 'promo'
                                    })}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <div style={{ width: '10%' }} className="nested td" >
                                      <span
                                        className="arrow-up"
                                        style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                        onClick={() => reportData.permissions.conversion_edit_data && setMoveMerge({
                                          mergeIdFrom: nestedItemFilter.merge_id,
                                          backChainFrom: item.back_chain,
                                          cidFrom: nestedItemFilter.cid,
                                          campaign: nestedItemFilter.campaign,
                                          campaign_id: nestedItemFilter.campaign_id,
                                          keyword: nestedItemFilter.keyword,
                                          id_field: nestedItemFilter.id,
                                          id_field_High: item.id,
                                          is_from_call: nestedItemFilter.type === 'call' ? nestedItemFilter.is_from_call : null,
                                          is_nested: false,
                                        })}
                                      ></span>
                                      <span
                                        className="arrow-down"
                                        style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                        onClick={() => reportData.permissions.conversion_edit_data && handleMoveDownButton(item.back_chain, nestedItemFilter.id)}
                                      ></span>
                                    </div>

                                    <div style={{ width: '90%' }}>
                                      <div className="__top">
                                        <div className="__left">
                                          <div>
                                            {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, nestedItemFilter.created_at)}
                                          </div>

                                          <div>
                                            {nestedItemFilter.ip}
                                          </div>

                                          <div> {nestedItemFilter.type !== 'order' && nestedItemFilter.ip_isp} </div>

                                          {
                                            nestedItemFilter.type === 'order' && nestedItemFilter.order_status && nestedItemFilter.order_status.toLowerCase() === 'canceled' &&
                                            <div className="order-status">
                                              <span>
                                                Canceled
                                              </span>
                                            </div>
                                          }

                                        </div>

                                        <div className="__right">
                                          <div>
                                            {nestedItemFilter.type !== 'call' && nestedItemFilter.cid}
                                            {/* {itemMerge.type === 'click' && itemMerge.client_phone} */}
                                          </div>
                                          <div>
                                            {nestedItemFilter.merge_id}
                                          </div>
                                          <div>
                                            {nestedItemFilter.type !== 'order' && nestedItemFilter.type !== 'call' && nestedItemFilter.page}
                                            {nestedItemFilter.type === 'call' && nestedItemFilter.friendly_name}
                                            {nestedItemFilter.type === 'order' && nestedItemFilter.order}
                                          </div>
                                          <div>
                                            {nestedItemFilter.type !== 'order' && nestedItemFilter.type !== 'call' && nestedItemFilter.ip_geo}
                                            {nestedItemFilter.type === 'order' && nestedItemFilter.back_chain}
                                            {nestedItemFilter.type === 'call' && nestedItemFilter.back_chain}
                                          </div>

                                          <div>
                                            {nestedItemFilter.type === 'call' && nestedItemFilter.dispatcher}
                                            {nestedItemFilter.type !== 'order' && nestedItemFilter.type !== 'call' && nestedItemFilter.visitor_id}
                                            {nestedItemFilter.type === 'order' && nestedItemFilter.dispatcher}
                                            {/* {item.fifth_one} */}
                                          </div>
                                          <div>
                                            {nestedItemFilter.type === 'send_form' && nestedItemFilter.back_chain}
                                            {nestedItemFilter.type === 'call' && `${nestedItemFilter.call_direction ? nestedItemFilter.call_direction : ''} / ${nestedItemFilter.call_status ? nestedItemFilter.call_status : ''}`}
                                            {nestedItemFilter.type === 'order' && `${nestedItemFilter.campaign ? nestedItemFilter.campaign : ''} ${nestedItemFilter.campaign_id ? '(' + nestedItemFilter.campaign_id + ')' : ''}`}
                                            {/* {item.fifth_two}  */}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="__bottom">
                                        <div className="__left">
                                          <div>{nestedItemFilter.type}</div>
                                          {
                                            nestedItemFilter.type === 'surf' && nestedItemFilter.surf_duration &&
                                            <div>
                                              ({nestedItemFilter.surf_duration})
                                            </div>
                                          }
                                        </div>

                                        <div style={{ marginLeft: '10px', overflow: 'hidden' }} className="__right">
                                          <div>
                                            {nestedItemFilter.type === 'promo' && nestedItemFilter.client_phone}
                                            {nestedItemFilter.type === 'order' && nestedItemFilter.client_phone}
                                            {nestedItemFilter.type === 'call' && nestedItemFilter.client_phone}
                                            {nestedItemFilter.type === 'send_form' && nestedItemFilter.client_phone}
                                            {nestedItemFilter.type === 'click' && `${nestedItemFilter.campaign ? nestedItemFilter.campaign : ''} ${nestedItemFilter.campaign_id ? '(' + nestedItemFilter.campaign_id + ')' : ''}`}

                                          </div>
                                          <div>
                                            {nestedItemFilter.type === 'surf' && nestedItemFilter.phone}
                                            {nestedItemFilter.type === 'click_call' && nestedItemFilter.phone}
                                            {nestedItemFilter.type === 'promo' && nestedItemFilter.phone}
                                            {
                                              nestedItemFilter.type === 'order' &&
                                              getServiceRow(nestedItemFilter.service_resource, nestedItemFilter.zip)
                                            }

                                            {nestedItemFilter.type === 'click' &&
                                              <p className="text-dots" title={nestedItemFilter.gclid} >
                                                {nestedItemFilter.gclid}
                                              </p>
                                            }

                                            {nestedItemFilter.type === 'call' && nestedItemFilter.phone &&
                                              ((selectedBackChain.id === item.id || selectedPhone.id === item.id) ?
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                  }}
                                                  className={classNames({
                                                    selected: nestedItemFilter.id === nestedSelectedPhone.id
                                                  })}
                                                  onClick={() => reportData.permissions.conversion_edit_data && handleNestedPhone(nestedItemFilter.phone, nestedItemFilter.id, nestedItemFilter.created_at)}
                                                >
                                                  <Icon
                                                    viewBox="0 0 16 16"
                                                    style={{
                                                      width: '28px',
                                                      minWidth: '16px',
                                                      height: '16px',
                                                      marginRight: '5px',
                                                      cursor: reportData.permissions.conversion_edit_data ? 'pointer' : 'inherit',
                                                      opacity: reportData.permissions.conversion_edit_data ? .5 : .2
                                                    }}
                                                    icon="download-1"
                                                  />
                                                  {nestedItemFilter.phone}
                                                </div> : nestedItemFilter.phone)
                                            }
                                          </div>
                                          <div>
                                            {nestedItemFilter.type === 'send_form' && nestedItemFilter.message}
                                            {nestedItemFilter.type === 'click' && nestedItemFilter.keyword}
                                            {nestedItemFilter.type === 'order' && nestedItemFilter.appliances_brands}
                                            {nestedItemFilter.type === 'promo' && nestedItemFilter.email}
                                          </div>
                                          {
                                            nestedItemFilter.type === 'call' &&
                                            nestedItemFilter.call_recording_url &&
                                            <div
                                              style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                              <button
                                                className="_zeroed _iconed"
                                                onClick={() => setCallListenPopup(nestedItemFilter.call_recording_url)}
                                              >
                                                <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                              </button>
                                              <span style={{ marginLeft: '5px' }}> {nestedItemFilter.call_duration} </span>
                                            </div>
                                          }
                                          <div style={{ position: 'relative' }}>

                                            {nestedItemFilter.type === 'surf' && nestedItemFilter.client_time}
                                            {nestedItemFilter.type === 'click_call' && nestedItemFilter.client_time}
                                            {nestedItemFilter.type === 'click' && nestedItemFilter.ads}
                                            {/* {itemMerge.type === 'call' && itemMerge.back_chain} */}
                                            {/* {itemMerge.type === 'send_form' && itemMerge.back_chain} */}
                                            {nestedItemFilter.type === 'promo' && nestedItemFilter.back_chain}
                                            {nestedItemFilter.type === 'order' && nestedItemFilter.keyword}

                                            {
                                              !!nestedItemFilter.is_sent_conversion &&
                                              <Icon
                                                viewBox="0 0 1024 1024"
                                                style={{
                                                  width: '16px',
                                                  minWidth: '16px',
                                                  height: '16px',
                                                  position: 'absolute',
                                                  top: '10px',
                                                  right: '10px'
                                                }}
                                                icon="send-21"
                                              />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }

                            {/* nested Phone table */}
                            {
                              item.type === 'order' &&
                              (nestedSelectedPhone.id === itemMerge.id) &&
                              nestedList.map((itemNested, idx) => (
                                <div
                                  key={idx}
                                  className={classNames('tr item nested-items',
                                    {
                                      order: itemNested.type === 'order',
                                      call: itemNested.type === 'call',
                                      send_form: itemNested.type === 'send_form',
                                      visit: itemNested.type === 'visit',
                                      click_call: itemNested.type === 'click_call',
                                      click: itemNested.type === 'click',
                                      surf: itemNested.type === 'surf',
                                      promo: itemNested.type === 'promo'
                                    })}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <div
                                      className="nested nested-phone td"
                                      style={{ alignItems: 'flex-end', width: '10%', display: 'flex' }}
                                    >
                                      <span
                                        className="arrow-up"
                                        style={{ opacity: reportData.permissions.conversion_edit_data ? 1 : .5 }}
                                        onClick={() => reportData.permissions.conversion_edit_data && setMoveMerge({
                                          mergeIdFrom: itemNested.merge_id,
                                          backChainFrom: item.back_chain,
                                          cidFrom: itemNested.cid,
                                          campaign: itemNested.campaign,
                                          campaign_id: itemNested.campaign_id,
                                          keyword: itemNested.keyword,
                                          id_field: itemNested.id,
                                          id_field_High: item.id,
                                          is_from_call: null,
                                          is_nested: true,
                                        })}
                                      ></span>
                                    </div>

                                    <div style={{ width: '85%' }}>
                                      <div className="__top">
                                        <div className="__left">
                                          <div>
                                            {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, itemNested.created_at)}
                                          </div>

                                          <div> {itemNested.ip} </div>

                                          <div> {itemNested.ip_isp} </div>

                                        </div>

                                        <div className="__right">
                                          <div>
                                            {itemNested.type !== 'call' && itemNested.type !== 'click' && itemNested.cid}
                                            {itemNested.type === 'click' && itemNested.client_phone}
                                          </div>
                                          <div>
                                            {itemNested.merge_id}
                                          </div>
                                          <div>
                                            {itemNested.type !== 'order' && itemNested.type !== 'call' && itemNested.page}
                                            {itemNested.type === 'call' && itemNested.friendly_name}
                                            {itemNested.type === 'order' && itemNested.order}
                                          </div>
                                          <div>
                                            {itemNested.type !== 'order' && itemNested.type !== 'call' && itemNested.ip_geo}
                                            {itemNested.type === 'order' && itemNested.back_chain}
                                            {itemNested.type === 'call' && itemNested.back_chain}
                                          </div>

                                          <div>
                                            {itemNested.type === 'call' && itemNested.dispatcher}
                                            {itemNested.type !== 'order' && itemNested.type !== 'call' && itemNested.visitor_id}
                                            {itemNested.type === 'order' && itemNested.dispatcher}
                                            {/* {item.fifth_one} */}
                                          </div>
                                          <div>
                                            {itemNested.type === 'send_form' && itemNested.back_chain}
                                            {itemNested.type === 'call' && `${itemNested.call_direction ? itemNested.call_direction : ''} / ${itemNested.call_status ? itemNested.call_status : ''}`}
                                            {itemNested.type === 'order' && `${itemNested.campaign ? itemNested.campaign : ''} ${itemNested.campaign_id ? '(' + itemNested.campaign_id + ')' : ''}`}
                                            {/* {item.fifth_two}  */}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="__bottom">
                                        <div className="__left">
                                          <div>{itemNested.type}</div>
                                          {
                                            itemNested.type === 'surf' && itemNested.surf_duration &&
                                            <div>
                                              ({itemNested.surf_duration})
                                            </div>
                                          }
                                        </div>

                                        <div style={{ marginLeft: '10px', overflow: 'hidden' }} className="__right">
                                          <div>
                                            {itemNested.type === 'promo' && itemNested.client_phone}
                                            {itemNested.type === 'order' && itemNested.client_phone}
                                            {itemNested.type === 'call' && itemNested.client_phone}
                                            {itemNested.type === 'send_form' && itemNested.client_phone}
                                            {itemNested.type === 'click' && `${itemNested.campaign ? itemNested.campaign : ''} ${itemNested.campaign_id ? '(' + itemNested.campaign_id + ')' : ''}`}
                                          </div>
                                          <div>
                                            {itemNested.type === 'surf' && itemNested.phone}
                                            {itemNested.type === 'click_call' && itemNested.phone}
                                            {itemNested.type === 'promo' && itemNested.phone}
                                            {
                                              itemNested.type === 'order' &&
                                              getServiceRow(itemNested.service_resource, itemNested.zip)
                                            }

                                            {itemNested.type === 'click' &&
                                              <p className="text-dots" title={itemNested.gclid} >
                                                {itemNested.gclid}
                                              </p>
                                            }

                                            {itemNested.type === 'call' && itemNested.phone}
                                          </div>
                                          <div>
                                            {itemNested.type === 'send_form' && itemNested.message}
                                            {itemNested.type === 'click' && itemNested.keyword}
                                            {itemNested.type === 'order' && itemNested.appliances_brands}
                                            {itemNested.type === 'promo' && itemNested.email}
                                          </div>
                                          {
                                            itemNested.type === 'call' &&
                                            itemNested.call_recording_url &&
                                            <div
                                              style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                              <button
                                                className="_zeroed _iconed"
                                                onClick={() => setCallListenPopup(itemNested.call_recording_url)}
                                              >
                                                <Icon style={{ fill: '#000' }} icon="media-control-48" />
                                              </button>
                                              <span style={{ marginLeft: '5px' }}> {itemNested.call_duration} </span>
                                            </div>
                                          }
                                          <div style={{ position: 'relative' }}>

                                            {itemNested.type === 'surf' && itemNested.client_time}
                                            {itemNested.type === 'click_call' && itemNested.client_time}
                                            {itemNested.type === 'click' && itemNested.ads}
                                            {/* {itemNested.type === 'call' && itemNested.back_chain} */}
                                            {/* {itemNested.type === 'send_form' && itemNested.back_chain} */}
                                            {itemNested.type === 'promo' && itemNested.back_chain}
                                            {itemNested.type === 'order' && itemNested.keyword}

                                            {
                                              !!itemNested.is_sent_conversion &&
                                              <Icon
                                                viewBox="0 0 1024 1024"
                                                style={{
                                                  width: '16px',
                                                  minWidth: '16px',
                                                  height: '16px',
                                                  position: 'absolute',
                                                  top: '10px',
                                                  right: '10px'
                                                }}
                                                icon="send-21"
                                              />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </React.Fragment>
                        ))
                      }
                    </React.Fragment>
                  ))}
              </div>
            </div>

            {
              sendAllConversions.isOpen &&
              <div className="item-delete-popup"
              >
                {
                  sendAllConversions.is_sending ?
                    <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                      <div className="title">
                        Sent {sendAllConversions.success_send} / {sendAllConversions.is_canceled ? sendAllConversions.conversions.length : sendAllConversions.conversions.filter(item => item.order_status !== 'canceled').length} conversions
                      </div>

                      <div className="buttons">
                        <button
                          className="_bordered _green"
                          disabled={sendAllConversions.success_send !== (sendAllConversions.is_canceled ? sendAllConversions.conversions.length : sendAllConversions.conversions.filter(item => item.order_status !== 'canceled').length)}
                          onClick={() => setSendAllConversions({
                            conversions: [],
                            is_canceled: true,
                            isOpen: false,
                            is_sending: false,
                            success_send: 0,
                          })}
                        >
                          Close
                        </button>
                      </div>
                    </div> :
                    <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                      <div className="title">
                        Send {sendAllConversions.is_canceled ? sendAllConversions.conversions.length : sendAllConversions.conversions.filter(item => item.order_status !== 'canceled').length} conversions
                      </div>

                      <div className="popup-contents" >
                        <div className="legend-action-wrapper">
                          <label>Send conversions for cancelled orders:</label>

                          <div className="input-wrapper">
                            <Checkbox
                              contents=''
                              value={sendAllConversions.is_canceled}
                              onChange={() => setSendAllConversions({
                                ...sendAllConversions,
                                is_canceled: !sendAllConversions.is_canceled
                              })}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="buttons">
                        <button
                          className="_bordered _green"
                          onClick={() => setSendAllConversions({
                            conversions: [],
                            is_canceled: true,
                            isOpen: false,
                            is_sending: false,
                            success_send: 0,
                          })}
                        >
                          Cancel
                        </button>

                        <button
                          className="_bordered _blue"
                          disabled={!reportData.permissions.conversion_send || (sendAllConversions.is_canceled ? sendAllConversions.conversions.length === 0 : sendAllConversions.conversions.filter(item => item.order_status !== 'canceled').length === 0)}
                          onClick={() => reportData.permissions.conversion_send &&
                            ((conversionsView === 'google' || !['facebook', 'meta'].includes(localInterface.dateRangeType)) ? handleSendAllConversions() : handleSendAllConversionsFb())
                          }
                        >
                          Send
                        </button>
                      </div>
                    </div>
                }
              </div>
            }

            {
              !!sendCidPopup.cid &&
              <div className="item-delete-popup"
              // onClick={() => setDeleting(false)}
              >
                <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                  <div className="title">
                    Send a conversion
                  </div>

                  <div className="buttons">
                    <button
                      className="_bordered _green"
                      onClick={() => setSendCidPopup({
                        cid: '',
                        id: 0,
                        mergeId: ''
                      })}
                    >
                      Cancel
                    </button>

                    <button
                      className="_bordered _blue"
                      disabled={!reportData.permissions.conversion_edit_data}
                      onClick={() => reportData.permissions.conversion_edit_data && ((conversionsView === 'google' || !['facebook', 'meta'].includes(localInterface.dateRangeType)) ? handleSendSid() : handleSendSidFb())}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            }

            {
              !!moveOrderUnset &&
              <div className="item-delete-popup"
              // onClick={() => setDeleting(false)}
              >
                <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                  <div className="title">
                    Mark Order as unsent
                  </div>

                  <div className="buttons">
                    <button
                      className="_bordered _green"
                      onClick={() => setMoveOrderUnset(0)}
                    >
                      No
                    </button>

                    <button
                      className="_bordered _blue"
                      disabled={!reportData.permissions.conversion_edit_data}
                      onClick={() => reportData.permissions.conversion_edit_data && handleMoveOrderUnset()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            }

            {
              !!moveBackChain.backChainFrom &&
              <div className="item-delete-popup"
              // onClick={() => setDeleting(false)}
              >
                <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                  <div className="title">
                    Move BackChain from order
                  </div>

                  <div className="buttons">
                    <button
                      className="_bordered _green"
                      onClick={() => setMoveBackChain({
                        backChainFrom: '',
                        id_field: 0
                      })}
                    >
                      No
                    </button>

                    <button
                      className="_bordered _blue"
                      disabled={!reportData.permissions.conversion_edit_data}
                      onClick={() => reportData.permissions.conversion_edit_data && handleMoveBackChainFromOrder()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            }

            {
              callListenPopup &&
              <div className="popup callListenPopup" onClick={() => setCallListenPopup(null)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="call-player">
                    <AudioPlayer audioSrc={callListenPopup} />
                  </div>
                </div>
              </div>
            }

            {
              !!moveMerge.id_field &&
              <div className="item-delete-popup"
              // onClick={() => setDeleting(false)}
              >
                <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                  <div className="title">
                    Choosen move
                  </div>

                  <div className="popup-contents" >
                    {
                      moveMerge.mergeIdFrom &&
                      <div className="legend-action-wrapper">
                        <label>Move MergeId to higher levels:</label>

                        <div className="input-wrapper">
                          <Checkbox
                            contents=''
                            value={selectedMove.moveMergeId}
                            onChange={(value) => setSelectedMove({
                              moveMergeId: value,
                              moveCampaign: false,
                              moveClientId: false,
                              moveFromCall: false
                            })}
                          />
                        </div>
                      </div>
                    }

                    {
                      moveMerge.campaign && moveMerge.campaign_id && moveMerge.keyword &&
                      <div className="legend-action-wrapper">
                        <label>Move Campaign to order:</label>

                        <div className="input-wrapper">
                          <Checkbox
                            contents=''
                            value={selectedMove.moveCampaign}
                            onChange={(value) => setSelectedMove({
                              moveMergeId: false,
                              moveCampaign: value,
                              moveClientId: false,
                              moveFromCall: false
                            })}
                          />
                        </div>
                      </div>
                    }

                    {
                      moveMerge.cidFrom &&
                      <div className="legend-action-wrapper">
                        <label>Move ClientId to order:</label>

                        <div className="input-wrapper">
                          <Checkbox
                            contents=''
                            value={selectedMove.moveClientId}
                            onChange={(value) => setSelectedMove({
                              moveMergeId: false,
                              moveCampaign: false,
                              moveClientId: value,
                              moveFromCall: false
                            })}
                          />
                        </div>
                      </div>
                    }

                    {
                      moveMerge.is_from_call !== null &&
                      <div className="legend-action-wrapper">
                        <label>Move FromCall to order:</label>

                        <div className="input-wrapper">
                          <Checkbox
                            contents=''
                            value={selectedMove.moveFromCall}
                            onChange={(value) => setSelectedMove({
                              moveMergeId: false,
                              moveCampaign: false,
                              moveClientId: false,
                              moveFromCall: value
                            })}
                          />
                        </div>
                      </div>
                    }
                  </div>

                  <div className="buttons">
                    <button
                      className="_bordered _green"
                      onClick={() => setMoveMerge({
                        mergeIdFrom: '',
                        backChainFrom: '',
                        cidFrom: '',
                        campaign: '',
                        campaign_id: '',
                        keyword: '',
                        id_field: 0,
                        id_field_High: 0,
                        is_from_call: null,
                        is_nested: false,
                      })}
                    >
                      No
                    </button>

                    <button
                      className="_bordered _blue"
                      disabled={!selectedMove.moveMergeId && !selectedMove.moveCampaign && !selectedMove.moveClientId && !selectedMove.moveFromCall}
                      onClick={() => reportData.permissions.conversion_edit_data && handleMoveMergeFromOrder()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            }

            {/* Table controls */}
            <ReportTableControls
              isUnder={true}

              onMaxRowsChange={() => $setUpdater(Math.random())}

              amount={{
                total: reportData.interface.rows_all,
                start: reportData.interface.rows_start,
                end: reportData.interface.rows_end
              }}

              page={localInterface.page}
              maxPages={reportData.interface.max_pages}
              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
                $setUpdater(Math.random())
              }}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}
            />
          </div > :
          null
      }
    </>
  )
}
